import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Calculator() {
  const wpPerPanel = 420;
  const diyDiscount = 600;
  const electricityPrice = 0.5;
  let pricePerW50 = 0.75;
  let pricePerW100 = 1.3;
  const pricePerW0 = 0.19;
  const allowBattery = false;
  const batteryPrice = 2400;
  let powerEfficiency = 0.5;
  const injectionCompensation = 0.1;
  let taxes = 1.06;

  const { t } = useTranslation();
  const [solution, setSolution] = useState(1);
  const [consumption, setConsumption] = useState(3500);
  const [numberOfPanels, setNumberOfPanels] = useState(16);
  const [battery, setBattery] = useState(0);
  const [DIY, setDIY] = useState(0);
  const [showCalculation, setShowCalculation] = useState(0);
  const [price, setPrice] = useState(0);
  const [subsidy, setSubsidy] = useState(0);
  const [profit, setProfit] = useState(0);

  const onCalculateNow = () => {
    calculateSubsidy();

    if (solution !== 2 && battery === 1) {
      powerEfficiency = 0.5;
    } else if (allowBattery) {
      powerEfficiency = 0.3;
    }
    if (solution === 1) {
      calculate50Price();
    } else if (solution === 2) {
      calculate0Price();
    } else if (solution === 3) {
      calculate100Price();
    }
    calculateProfit();
    setShowCalculation(1);
  };

  const calculateSubsidy = () => {
    if (new Date().getFullYear() > 2024 || solution === 2) {
      setSubsidy(0);
      return;
    }

    let s = 750;
    if (numberOfPanels * wpPerPanel < 4000) {
      s = 0.15 * numberOfPanels * wpPerPanel;
    } else if (numberOfPanels * wpPerPanel < 6000) {
      s = 600 + 0.075 * (numberOfPanels * wpPerPanel - 4000);
    }

    if (new Date().getFullYear() === 2024) {
      s = s / 2;
    }
    setSubsidy(s);
  };

  const calculate50Price = () => {
    switch (numberOfPanels) {
      case 14:
        pricePerW50 = 0.76;
        break;
      case 13:
        pricePerW50 = 0.79;
        break;
      case 12:
        pricePerW50 = 0.82;
        break;
      case 11:
        pricePerW50 = 0.86;
        break;
      case 10:
        pricePerW50 = 0.91;
        break;
      case 9:
        pricePerW50 = 0.97;
        break;
      case 8:
        pricePerW50 = 1.04;
        break;
      default:
        pricePerW50 = 0.75;
        break;
    }
    const basePrice = numberOfPanels * wpPerPanel * pricePerW50;
    const basePriceAugmented =
      basePrice + (battery === 1 ? batteryPrice : 0) + /*(charger ? chargerPrice : 0)*/ -(DIY ? diyDiscount : 0);
    setPrice(basePriceAugmented * taxes);
  };

  const calculate0Price = () => {
    setPrice(0);
  };

  const calculate100Price = () => {
    if (numberOfPanels < 15) {
      let extraCost = 0.05;
      let i = numberOfPanels;
      while (i < 15) {
        pricePerW100 += extraCost;
        i++;
      }
    }
    const basePrice = numberOfPanels * wpPerPanel * pricePerW100;
    const basePriceAugmented =
      basePrice +
      (battery === 1 ? batteryPrice * pricePerW100 : 0) /*+ (charger ? chargerPrice : 0)*/ -
      (DIY ? diyDiscount : 0);
    setPrice(basePriceAugmented * taxes);
  };

  const calculateProfit = () => {
    if (solution === 1) {
      setProfit(numberOfPanels * wpPerPanel * powerEfficiency * electricityPrice);
    }
    if (solution === 2) {
      const costToSS = numberOfPanels * wpPerPanel * powerEfficiency * pricePerW0;
      setProfit(numberOfPanels * wpPerPanel * powerEfficiency * electricityPrice - costToSS);
    }
    if (solution === 3) {
      const immediateConsumptionProfit = numberOfPanels * wpPerPanel * powerEfficiency * electricityPrice;
      const injectionProfit = numberOfPanels * wpPerPanel * (1 - powerEfficiency) * injectionCompensation;

      setProfit(immediateConsumptionProfit + injectionProfit);
      return 0;
    }
  };

  const calculatePaybackPeriod = () => {
    if (solution === 2) {
      return 0;
    }
    return ((price - subsidy) / profit).toFixed(2);
  };

  return (
    <div>
      <div class="container-fluid treesBackgroundImage">
        <div class="container solutionsContainer">
          <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <div class="col-12">
              <h2>{t("calculator_title")}</h2>
              <p class="darkText">{t("calculator_subtitle")}</p>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3 smallMarginBottom">
              <span>{t("calculator_chosen_solution")}</span>
              <select
                class="form-select"
                aria-label="Choose solution"
                onChange={(e) => {
                  setSolution(parseInt(e.target.value));
                  setBattery(0);
                  setShowCalculation(0);
                }}
              >
                <option value="1">{t("solutions_50title")}</option>
                <option value="2">{t("solutions_0title")}</option>
                <option value="3">{t("solutions_100title")}</option>
              </select>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2 smallMarginBottom">
              <span>{t("calculator_chosen_consumption")}</span>
              <select
                class="form-select"
                aria-label="set consumption"
                defaultValue={"3500"}
                onChange={(e) => {
                  setConsumption(parseInt(e.target.value));
                  setShowCalculation(0);
                }}
              >
                <option value="1000">1000kWh</option>
                <option value="1500">1500kWh</option>
                <option value="2000">2000kWh</option>
                <option value="2500">2500kWh</option>
                <option value="3000">3000kWh</option>
                <option value="3500">3500kWh</option>
                <option value="4000">4000kWh</option>
                <option value="4500">4500kWh</option>
                <option value="5000">5000kWh</option>
                <option value="5500">5500kWh</option>
                <option value="6000">6000kWh</option>
                <option value="6500">6500kWh</option>
                <option value="7000">7000kWh</option>
                <option value="7500">7500kWh</option>
                <option value="8000">8000kWh</option>
              </select>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-2 smallMarginBottom">
              <span>{t("calculator_amount_panels")}</span>
              <select
                class="form-select"
                aria-label="select number of panels"
                defaultValue={"16"}
                onChange={(e) => {
                  setNumberOfPanels(parseInt(e.target.value));
                  setShowCalculation(0);
                }}
              >
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">{t("configurator_question2option2")}</option>
              </select>
            </div>
            {allowBattery && solution !== 2 && (
              <div class="col-sm-12 col-md-4 col-lg-2 smallMarginBottom">
                <span>{t("calculator_battery")}</span>
                <select
                  class="form-select"
                  aria-label="set battery"
                  value={battery.toString()}
                  onChange={(e) => {
                    setBattery(parseInt(e.target.value));
                    setShowCalculation(0);
                  }}
                >
                  <option value="0">{t("no")}</option>
                  <option value="1">{t("yes")}</option>
                </select>
              </div>
            )}
            <div class="col-sm-12 col-md-4 col-lg-3 smallMarginBottom">
              <span>{t("calculator_diy")}</span>
              <select
                class="form-select"
                aria-label="choose DIY"
                onChange={(e) => {
                  setDIY(parseInt(e.target.value));
                  setShowCalculation(0);
                }}
              >
                <option value="0">{t("no")}</option>
                <option value="1">{t("yes")}</option>
              </select>
            </div>
            <div class="col-12">
              <button
                class="btn btn-success whiteColor"
                onClick={() => {
                  onCalculateNow();
                }}
              >
                <span class="bold fontsize15">{t("calculator_calculate")}</span>
              </button>
            </div>

            <div class="col-12">
              {showCalculation === 1 && (
                <table class="table table-bordered smallMarginTop">
                  <tbody>
                    <tr>
                      <th scope="row">{t("calculator_consumption")}</th>
                      <td>{consumption}kWh</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("calculator_current_cost")}</th>
                      <td>€0,50</td>
                    </tr>
                    <tr class="table-dark">
                      <th scope="row">{t("calculator_current_yearcost")}</th>
                      <td>€{consumption * 0.5}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("calculator_power")}</th>
                      <td>{numberOfPanels * wpPerPanel}kWh</td>
                    </tr>
                    {solution !== 2 && (
                      <tr>
                        <th scope="row">
                          {t("calculator_our_price")} {battery === 1 && ", " + t("calculator_battery")}
                        </th>
                        <td>€{price.toFixed(0)}</td>
                      </tr>
                    )}
                    {solution !== 2 && (
                      <tr>
                        <th scope="row">{t("calculator_subsidy")}</th>
                        <td>€{-1 * subsidy.toFixed(0)}</td>
                      </tr>
                    )}
                    <tr class="table-dark">
                      <th scope="row">{t("calculator_final_cost")}</th>
                      <td>€{(price - subsidy).toFixed(0)}</td>
                    </tr>
                    {solution === 2 && (
                      <tr class="table-success">
                        <th scope="row">{t("calculator_cost_per_year")}</th>
                        <td>€{(numberOfPanels * wpPerPanel * powerEfficiency * pricePerW0).toFixed(0)}</td>
                      </tr>
                    )}
                    <tr class="table-success">
                      <th scope="row">{t("calculator_profit_per_year")}</th>
                      <td>€{profit.toFixed(0)}</td>
                    </tr>
                    <tr class="table-success">
                      <th scope="row">{t("calculator_payback_time")}</th>
                      <td>
                        {calculatePaybackPeriod()} {t("calculator_year")} {solution === 2 && t("calculator_no_cost")}
                      </td>
                    </tr>
                    <tr class="table-success">
                      {solution === 1 && <td colSpan="2">{t("calculator_profit_explanation50")}</td>}
                      {solution === 2 && <td colSpan="2">{t("calculator_profit_explanation0")}</td>}
                      {solution === 3 && <td colSpan="2">{t("calculator_profit_explanation100")}</td>}
                    </tr>
                  </tbody>
                </table>
              )}
              {showCalculation === 1 && solution !== 2 && (
                <span class="accentColor noPadding">{t("calculator_power_efficiency2")}</span>
              )}
            </div>
          </div>

          <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <div class="col-12">
              <h3 class="accentColor">{t("calculator_price_title")}</h3>
              <p>{t("calculator_price_indicative")}</p>
              <p>{t("calculator_price_indicative2")}</p>
              <div class="row justify-content-center marginTop roundedCard greyContainer">
                <a class="noDecoration containerPadding" href={window.location.origin + "/product"}>
                  <div class="col-12 text-center">
                    <h5 class="whiteColor">{t("home_solutionquality")}</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
