import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import i18n from "../i18n";

export default function ConfiguratorSummary({ onPreviousClick, onSend }) {
  const { t } = useTranslation();
  const captchaRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [extra, setExtra] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [recaptchaOK, setRecaptchaOK] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showCaptchaAlert, setShowCaptchaAlert] = useState(false);
  const [sendPressed, setSendPressed] = useState(false);

  const handleCaptchaSubmit = async (token) => {
    await axios
      .post(process.env.REACT_APP_CAPTCHA_VALIDATION_URL, { token })
      .then((res) => {
        if (res.status === 200) {
          setRecaptchaOK(1);
          setShowCaptchaAlert(0);
        }
      })
      .catch((error) => {
        console.log("Error response: " + error);
        //TODO show error message
      });

    captchaRef.current.reset();
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setSendPressed(true);

    const formElement = e.target;
    const isValid = formElement.checkValidity();

    if (!isValid) {
      formElement.classList.add("submitted");
      const firstInvalidField = formElement.querySelector(":invalid");
      firstInvalidField?.focus();
      setShowAlert(true);
      if (!isOwner) {
        setShowPopup(true);
      }
      return;
    }
    if (recaptchaOK === 0) {
      setShowCaptchaAlert(true);
      return;
    }
    const lang = i18n.resolvedLanguage;
    const address = street + " " + number + " " + extra + ", " + zip + " " + city;
    const personalInfo = { name, email, phone, address, lang };
    onSend(personalInfo);
  };

  return (
    <div class="border bg-light container roundedCard darkText summarySlide">
      {showPopup && !isOwner && (
        <div class="smallPadding popupContainer">
          <div class="popup whiteTransparentOverlay roundedCard smallPadding">
            <h5>{t("configurator_sorry")}</h5>
            <br />
            <p>{t("configurator_notowner")}</p>
            <button
              type="button"
              class="btn btn-success"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              {t("home_contactmodal_close")}
            </button>
          </div>
        </div>
      )}
      <div class="row">
        <div class="col smallMarginTop">
          <h2 class="noMargin">
            <i className="marginBottom fa-solid fa-envelope fa-2xl accentColor" />
          </h2>
        </div>
      </div>
      <form class="needs-validation" noValidate onSubmit={(e) => formSubmit(e)}>
        <div class="row d-flex justify-content-center">
          <div class="input-group">
            <input
              id="name"
              type="text"
              aria-label="Full name"
              class="form-control"
              placeholder={t("configurator_summary_name")}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div class="input-group">
            <input
              id="email"
              type="email"
              class="form-control"
              aria-label="Email"
              aria-describedby="email-label"
              placeholder={t("configurator_summary_email")}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div class="input-group mb-3">
            <input
              id="phone"
              type="text"
              class="form-control"
              aria-label="Phone"
              aria-describedby="phone-label"
              placeholder={t("configurator_summary_phone")}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div class="input-group mb-3">
            <div class="col-6">
              <input
                id="street"
                type="text"
                class="form-control"
                aria-label="Street"
                placeholder={t("configurator_summary_street")}
                onChange={(e) => setStreet(e.target.value)}
                required
              />
            </div>
            <div class="col-3">
              <input
                id="number"
                type="number"
                class="form-control"
                aria-label="Number"
                placeholder={t("configurator_summary_number")}
                onChange={(e) => setNumber(e.target.value)}
                required
              />
            </div>
            <div class="col-3">
              <input
                id="extra"
                type="text"
                class="form-control"
                aria-label="Extra"
                placeholder={t("configurator_summary_extra")}
                onChange={(e) => setExtra(e.target.value)}
              />
            </div>
            <div class="col-4">
              <input
                id="zip"
                type="text"
                class="form-control"
                aria-label="Zip"
                placeholder={t("configurator_summary_zip")}
                onChange={(e) => setZip(e.target.value)}
                required
              />
            </div>
            <div class="col-8">
              <input
                id="city"
                type="text"
                class="form-control"
                aria-label="City"
                placeholder={t("configurator_summary_city")}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
          </div>

          <div class="smallMarginBottom">
            <input
              class="form-check-input smallMarginRight"
              type="checkbox"
              onChange={(e) => {
                setIsOwner(e.target.checked);
              }}
              id="ownerCheck"
              required
            />
            <label class={(sendPressed && !isOwner && "redColor ") + "form-check-label"} htmlFor="ownerCheck" required>
              {t("configurator_summary_owner")}
            </label>
          </div>

          {recaptchaOK === 0 && (
            <ReCAPTCHA
              class="d-flex justify-content-center"
              onChange={(key) => handleCaptchaSubmit(key)}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              ref={captchaRef}
            />
          )}
        </div>

        <div class="row smallMarginBottom smallMarginTop">
          {sendPressed && showAlert && (
            <div class="col-12">
              <div class="alert alert-danger" role="alert">
                {t("configurator_summary_reminder")}
              </div>
            </div>
          )}
          {sendPressed && showCaptchaAlert && (
            <div class="col-12">
              <div class="alert alert-danger" role="alert">
                {t("configurator_summary_captcha_reminder")}
              </div>
            </div>
          )}
          <div class="col">
            <button class="btn btn-outline-success" onClick={() => onPreviousClick()}>
              {t("back")}
            </button>
          </div>
          <div class="col">
            <button class="btn btn-success" type="submit" id="submit">
              {t("configurator_send")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
