import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha"
import TextInput from "./TextInput";

export default function ContactForm() {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(0);
  const [sendStatus, setSendStatus] = useState(0);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const captchaRef = useRef(null);

  const handleCaptchaSubmit = async (token) =>{
    await axios.post(process.env.REACT_APP_CAPTCHA_VALIDATION_URL, {token})
      .then(res =>  {
        if (res.status === 200) {
          setValidated(1);
        }
      })
      .catch((error) => {
        console.log("Error response: " + error);
    })

    captchaRef.current.reset();
  }

  const formSubmit = (e) => {
    e.preventDefault();
    if (validated === 0) {
      return;
    }
    
    const formElement = e.target;
    const isValid = formElement.checkValidity();

    if (!isValid) {
      formElement.classList.add("submitted");
      const firstInvalidField = formElement.querySelector(":invalid");
      firstInvalidField?.focus();
    }

    const content = {email, phone, message};
    axios.post(process.env.REACT_APP_CONTACT_MESSAGE_URL, {content}).then(res =>  {
      if (res.status === 200) {
        setSendStatus(1);
      }
    })
    .catch((error) => {
      console.log("Error response: " + error);
      setSendStatus(2);
    })
  }
  
  return (
    <form class="needs-validation" noValidate onSubmit={(e) => formSubmit(e)}> 
        <div id="contactform">
          <div class="mb-3">
            <label htmlFor="sender-email" class="col-form-label">{t('home_contactmodal_email')}</label>
            <TextInput type="email" name="email" class="form-control" id="sender-email" onChange={e => setEmail(e.target.value)} required/>
          </div>
          <div class="mb-3">
            <label htmlFor="sender-phone" class="col-form-label">{t('home_contactmodal_phone')}</label>
            <TextInput type="tel" name="tel" class="form-control" id="sender-phone" onChange={e => setPhone(e.target.value)} required/>
          </div>
          <div class="mb-3">
            <label htmlFor="message-text" class="col-form-label">{t('home_contactmodal_message')}</label>
            <textarea class="form-control" id="message-text" rows="5" required onChange={e => setMessage(e.target.value)}/>
          </div>
      </div>
      <div class="modal-footer">
        {validated === 1 && (<button type="submit" class="btn btn-success" id="submit">{t('home_contactmodal_send')}</button>)}
      </div>
        {validated === 0 && (
          <div id="captcha">
            <p>{t('home_contactmodal_pleasecheck')}</p>
            <ReCAPTCHA
              onChange={(key) => handleCaptchaSubmit(key)}
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} 
              ref={captchaRef}
              />
          </div>
        )}
        {sendStatus === 2 && (<div class="alert alert-danger" role="alert">
          {t('home_contactmodal_failure')}
        </div>)}
        {sendStatus === 1 && (<div class="alert alert-success marginTop" role="alert">
        {t('home_contactmodal_phone_message')}. &nbsp;{t('home_contactmodal_success')}
        </div>)}
    </form>
  );
}