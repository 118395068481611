import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Thanks() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div>
      <div class="container-fluid treesBackgroundImage noOverflow">
        <div class="row">
          <div class="col darkText text-center">
            <h1>{t("configurator_title")}</h1>
            <p>{t("configurator_subtitle")}</p>
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-6">
                <div class="alert alert-success" role="alert">
                  {t("configurator_sent_ok1") + location.state.mail + t("configurator_sent_ok2")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
