import React from "react";
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation();
  
  return (
    <div id="error-page">
      <h1>{t('notfound_title')}</h1>
      <p>{t('notfound_description')}</p>
      <p>
        <i>{t('notfound_suggestion')}</i>
      </p>
    </div>
  );
}