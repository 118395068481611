import React from "react";
import { useTranslation } from "react-i18next";

export default function Result() {
  const { t } = useTranslation();

  return (
    <div>
      <div class="container-fluid treesBackgroundImage">
        <div class="container solutionsContainer">
          <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <div class="col-12">
              <h2>{t("result_title")}</h2>
              <p>{t("result_paragraph")}</p>
              <p><a class="fab fa-facebook" href="https://www.facebook.com/people/Solar-Switch/100092834117277/" target="_blank"><span class="normalFont">&nbsp;{t("result_facebook")}</span></a></p>
              {/*<p><a class="fab fa-instagram" href="" target="_blank"><span class="normalFont">&nbsp;{t("result_instagram")}</span></a></p>*/}
              <div class="row">
                <div class="col-12 col-md-6 text-center marginBottom">
                  <img class="serviceImage" alt="finished work 1" src={require("../images/result1.webp")} />
                </div>
                <div class="col-12 col-md-6 text-center marginBottom">
                  <img class="serviceImage" alt="finished work 3" src={require("../images/result4.webp")} />
                </div>
                <div class="col-12 col-md-6 text-center marginBottom">
                  <img class="serviceImage" alt="finished work 3" src={require("../images/result3.webp")} />
                </div>
                <div class="col-12 col-md-6 text-center marginBottom">
                  <img class="serviceImage" alt="finished work 2" src={require("../images/result2.webp")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
