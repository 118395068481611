import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

export default function ConfiguratorQuestion2({onPreviousClick, onNextClick, onNumberOfPanelsSelected}) {
  const { t } = useTranslation();
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [numberOfPanels, setNumberOfPanels] = useState(0);

  const onAnswerQuestion = () => {
    setQuestionAnswered(1);
    if(numberOfPanels === 0) {
      return;
    }
    onNextClick();
  }

  const panelAmountUpdate = (amount) => {
    const amountAsInt = parseInt(amount, 10);
    setNumberOfPanels(amountAsInt);
    onNumberOfPanelsSelected(amountAsInt);
  }

   return(
    <div class="border bg-light container roundedCard darkText">
      <div class="row">
        <div class="col smallMarginTop">
          <i className="marginBottom fa fa-solar-panel fa-2xl"></i>
          <p>{t('configurator_question2')}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-sm-6">
          <select class="form-select smallMarginBottom" aria-label="select number of panels" onChange={(e) => panelAmountUpdate(e.target.value)} value={numberOfPanels}>
              <option disabled={true} value="0">{t('configurator_question2placeholder')}</option>
              <option value="5">{t('configurator_question2option1')}</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">{t('configurator_question2option2')}</option>
          </select>
          <p>{t('configurator_question2subtitle')}</p>
        </div>
        {numberOfPanels === 0 && questionAnswered === 1 && (
          <div class="row">
            <div class="alert alert-danger" role="alert">{t('configurator_question2reminder')}</div>
          </div>
          )}
      </div>
      <div class="row smallMarginBottom smallMarginTop">
        <div class="col"><button class="configuratorButton btn btn-outline-success"  onClick={() => onPreviousClick()}>{t('back')}</button></div>
        <div class="col"><button class="configuratorButton btn btn-success" onClick={() => onAnswerQuestion()}>{t('ok')}</button></div>
      </div>
    </div>
  );
}