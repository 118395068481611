import "../App.css";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ContactForm from "../components/ContactForm";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewCompleted: false,
      worksiteList: [],
      modal: false,
      activeItem: {
        title: "",
        description: "",
        completed: false,
      },
    };
  }

  render() {
    return (
      <div>
        <div class="container-fluid treesBackgroundImage">
          <div class="container marginDefault">
            <div class="row justify-content-between">
              <div class="col-12 col-md-6 titleContainer">
                <h1>{this.props.t("home_title")}</h1>
                <h2 class="darkText">{this.props.t("home_subtitle")}</h2>
                <h2>
                  <a class="bold accentLink" href="#50">
                    {this.props.t("home_solutionlinks")}
                  </a>
                </h2>
              </div>
              <div class="col-sm-12 col-md-5 col-lg-4 orderconfigurator">
                <h1>{this.props.t("home_ordertitle")}</h1>
                <p>{this.props.t("home_ordersubtitle")}</p>
                <a class="btn btn-success whiteColor" href="/configurator">
                  <span class="bold fontsize15">{this.props.t("home_orderstartbutton")}</span>
                </a>
              </div>
            </div>
            <div class="row justify-content-center bigMarginTop roundedCard whiteExtraTransparentOverlay">
              <a class="noDecoration containerPadding" href={window.location.origin + "/configurator"}>
                <div class="col-12 text-center">
                  <h5 class="darkText bold">{this.props.t("nav_traditional")}</h5>
                  <h5 class="darkText">{this.props.t("calculator_amount_panels")} 14 (5880kWh)</h5>
                  <h1 class="darkText bold">€5990</h1>
                  <h3 class="darkText bold"></h3>
                  <h2 class="accentColor">
                    {this.props.t("home_curious")}
                  </h2>
                </div>
              </a>
            </div>
          </div>
        </div>


        <div id="100" class="container-fluid greyContainer whiteColor customContainer containerPadding">
            <div class="col-12">
              <h2>{this.props.t('solutions_100title')}</h2>
              <p class="italic">{this.props.t('solutions_100subtitle')}</p>

              <div class="row">
                <div class="col-12 col-md-8 smallMarginBottom">
                  <p><span class="bold">{this.props.t('solutions_small_letters')}</span>{this.props.t('solutions_100paragraph1')}</p>
                  <p>{this.props.t('solutions_100paragraph2')}</p>
                </div>
                <div class="col-12 col-md-4 smallMarginBottom">
                  <img class="fullWidthImage" alt="helpdesk employee" src={require("../images/safeinvestment.webp")} />
                </div>
              </div>
            </div>
            <a class="smallMarginTop" href="/faq">{this.props.t('solutions_100questions')}</a>
          </div>

        {/*<div id="50" class="container-fluid containerPadding whiteBackground darkText">
          <div class="row">
            <h2 class="marginBottom">{this.props.t("home_50title")}</h2>
            <div class="col d-flex align-items-center">
              <span>
                <h3 class="marginBottom">{this.props.t("home_50subtitle")}</h3>
                <p>{this.props.t("home_50paragraph1")}</p>
              </span>
            </div>
            <div class="col-12 col-lg-5 d-flex align-items-center">
              <img class="fullWidthImage" alt="solar panel image" src={require("../images/result1.webp")} />
            </div>
            <div class="col d-flex align-items-center smallMarginTop">
              <span>
                <p>{this.props.t("home_50paragraph2")}</p>
                <a class="accentLink" href={window.location.origin + "/solutions#50"}>
                  <span class="bold">{this.props.t("home_howlink")}</span>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div id="solutions" class="container-fluid greyContainer whiteColor customContainer containerPadding">
          <div class="row customContainer d-flex align-items-center">
            <div class="container px-4">
              <h2 class="marginBottom">{this.props.t("home_solutionstitle")}</h2>
              <div class="row gx-5 gy-3 justify-content-between">
                <div class="col-xs-12 col-lg-4">
                  <div class="p-3 border bg-light roundedCard">
                    <h3 class="darkText">{this.props.t("home_solution3title")}</h3>
                    <p class="darkText">{this.props.t("home_solution3description")}</p>
                    <a href={window.location.origin + "/solutions#100"}>
                      <span class="bold accentLink">{this.props.t("home_solutiondetails")}</span>
                    </a>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-4">
                  <div class="p-3 border bg-light roundedCard">
                    <h3 class="darkText">{this.props.t("home_solution1title")}</h3>
                    <p class="darkText">{this.props.t("home_solution1description")}</p>
                    <a href={window.location.origin + "/solutions#0"}>
                      <span class="bold accentLink">{this.props.t("home_solutiondetails")}</span>
                    </a>
                  </div>
                </div>
                <div class="col-xs-12 col-lg-4">
                  <div class="p-3 border bg-light roundedCard">
                    <h3 class="darkText">{this.props.t("home_solution2title")}</h3>
                    <p class="darkText">{this.props.t("home_solution2description")}</p>
                    <a href={window.location.origin + "/solutions#50"}>
                      <span class="bold accentLink">{this.props.t("home_solutiondetails")}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center marginTop roundedCard whiteBackground">
                <a class="noDecoration containerPadding" href={window.location.origin + "/product"}>
                  <div class="col-12 text-center">
                    <h5 class="accentColor">{this.props.t("home_solutionquality")}</h5>
                  </div>
                </a>
              </div>
            </div>
          </div>
    </div>*/}

        <div id="battery" class="container whiteBackground">
          <div class="row customContainer justify-content-between">
            <div class="col containerPadding d-flex align-items-center">
              <span>
                <h2 class="marginBottom">{this.props.t("home_battery")}</h2>
                <p class="darkText">{this.props.t("home_battery_paragraph1")}</p>
              </span>
            </div>
            <div class="col-12 col-lg-6 noPadding  d-flex align-items-center">
              <img class="ourstoryimage customImage smallMarginBottom" alt="solar switch battery" src={require("../images/battery.jpeg")} />
              </div>
          </div>
        </div>

        <div id="faq" class="faqBackgroundImage d-flex align-items-center containerPadding">
          <div class="container">
            <div class="row">
              <div class="col whiteTransparentOverlay darkText roundedCard smallMarginBottom containerPadding">
                <span>
                  <h2 class="accentColor marginBottom">{this.props.t("home_faqtitle")}</h2>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion1")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion2")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion3")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion4")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion5")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion6")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion7")}</span>
                  </a>
                  <a class="btn btn-dark smallMarginTop smallMarginRight" href="/faq">
                    <span class="bold fontsizedefault">{this.props.t("home_faqquestion8")}</span>
                  </a>
                  <div class="col-12 marginTop">
                    <a class="accentLink" href="/faq">
                      <span class="bold">{this.props.t("home_faqmore")}</span>
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div id="aboutus" class="container whiteBackground">
          <div class="row customContainer justify-content-between">
            <div class="col containerPadding d-flex align-items-center">
              <span>
                <h2 class="marginBottom">{this.props.t("home_ourstory")}</h2>
                <p class="darkText">{this.props.t("home_ourstory_paragraph1")}</p>
              </span>
            </div>
            {/*<div class="col-12 col-lg-6 noPadding  d-flex align-items-center">
                <img class="ourstoryimage customImage smallMarginBottom" alt="solar switch warehouse" src={require("../images/solarswitch.webp")} />
                </div>*/}
          </div>
        </div>

        <div
          id="configurator"
          class="container-fluid solarpanelsBackgroundImage d-flex align-items-center containerPadding"
        >
          <div class="row  justify-content-end">
            <div class="col-12 col-md-6 darkTransparentOverlay containerPadding whiteColor roundedCard">
              <span>
                <h2 class="whiteColor marginBottom">{this.props.t("home_configurator1")}</h2>
                <p>{this.props.t("home_configurator2")}</p>
                <a class="btn btn-success whiteColor" href="/configurator">
                  <span class="bold fontsize15">{this.props.t("home_configurator3")}</span>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div id="contactus" class="container-fluid gradientbackground whiteColor d-flex align-items-center">
          <div class="row">
            <div class="col-12 col-md-7 containerPadding">
              <h2 class="whiteColor marginBottom">{this.props.t("home_contactustitle")}</h2>
              <p>{this.props.t("home_contactus1")}</p>
              <a type="button" href="/contact" class="btn btn-light marginBottom">
                <span class="bold fontsizedefault">{this.props.t("home_contactus")}</span>
              </a>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center noPadding">
              <img class="fullWidthImage" alt="helpdesk employee" src={require("../images/helpdesk.webp")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
