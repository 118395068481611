import React from "react";
import { useTranslation } from 'react-i18next';
import FAQQuestion from "../components/FAQQuestion";

export default function FAQ() {
  const { t } = useTranslation();

  return(    
    <div class="container-fluid faqBackgroundImage solutionsContainer">
        <div class="smallMarginBottom">
            <h2 class="whiteColor smallMarginTop">{t('faq_title')}</h2>
              <div class="row">
                <FAQQuestion id={"1"} />
                <FAQQuestion id={"2"} />
                <FAQQuestion id={"3"} />
                <FAQQuestion id={"4"} />
                <FAQQuestion id={"5"} />
                <FAQQuestion id={"6"} />
                <FAQQuestion id={"7"} />
                <FAQQuestion id={"8"} />
                <FAQQuestion id={"9"} />
              </div>
          </div>

          {/*<div class="col-12 col-sm-6 col-lg-4">
            <h2 class="whiteColor smallMarginTop">{t('faq_subtitle50')}</h2>
            <FAQQuestion id={"50_1"} />
            <FAQQuestion id={"50_2"} />
            <FAQQuestion id={"50_3"} />
          </div>

          <div class="col-12 col-sm-6 col-lg-4">
            <h2 class="whiteColor smallMarginTop">{t('faq_subtitle0')}</h2>
            <FAQQuestion id={"0_1"} />
            <FAQQuestion id={"0_2"} />
  </div>*/}
      </div>
  );
}