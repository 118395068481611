import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ConfiguratorQuestion1({ onPreviousClick, onNextClick, onRoofMaterialSelected }) {
  const { t } = useTranslation();
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [previousMaterial, setPreviousMaterial] = useState(null);

  const setHighlight = (element) => {
    element.classList.add("highlightImage");
  };

  const removeSelected = (element, roofMat) => {
    if (previousMaterial === roofMat) {
      return;
    }
    element.classList.remove("highlightImage");
  };

  const setRoofMaterial = (element, roofMaterial) => {
    setQuestionAnswered(1);
    onRoofMaterialSelected(roofMaterial);
    if (previousMaterial !== null) {
      previousMaterial.classList.remove("selectedImage");
      previousMaterial.classList.remove("highlightImage");
    }
    element.classList.add("selectedImage");
    setPreviousMaterial(element);
  };

  const onAnswerQuestion = () => {
    setQuestionAnswered(1);
    if (previousMaterial === null) {
      return;
    }
    onNextClick();
  };

  return (
    <div class="border bg-light container roundedCard darkText">
      <div class="row">
        <div class="col smallMarginTop">
          <i className="marginBottom fa fa-house fa-2xl" />
          <p>{t("configurator_question1")}</p>
          <div class="row smallMarginBottom">
            <div class="col-6 col-md-4 imageContainer d-flex align-items-center smallMarginBottom">
              <img
                class="configuratorImage"
                alt="dakpannen"
                src={require("../images/dakpan.webp")}
                onMouseEnter={(e) => {
                  setHighlight(e.target);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target, 1);
                }}
                onClick={(e) => setRoofMaterial(e.target, 1)}
              />
              <p
                class="textOverImage"
                onMouseEnter={(e) => {
                  setHighlight(e.target.previousSibling);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target.previousSibling, 1);
                }}
                onClick={(e) => setRoofMaterial(e.target.previousSibling, 1)}
              >
                {t("configurator_question1option1")}
              </p>
            </div>
            {/*<div class="col-6 col-md-3 imageContainer d-flex align-items-center smallMarginBottom" >
                    <img class="configuratorImage" alt="natuurleien" src={require("../images/shingle.webp")}
                    onMouseEnter={(e) => {setHighlight(e.target)}} 
                    onMouseLeave={(e) => {removeSelected(e.target, 2)}} 
                    onClick={(e) => setRoofMaterial(e.target,2)} />
                    <p class="textOverImage"
                    onMouseEnter={(e) => {setHighlight(e.target.previousSibling)}} 
                    onMouseLeave={(e) => {removeSelected(e.target.previousSibling, 2)}} 
                    onClick={(e) => setRoofMaterial(e.target.previousSibling,2)}>{t('configurator_question1option2')}</p>
                  </div>*/}
            <div class="col-6 col-md-4 imageContainer d-flex align-items-center smallMarginBottom">
              <img
                class="configuratorImage"
                alt="EPDM"
                src={require("../images/EPDM.webp")}
                onMouseEnter={(e) => {
                  setHighlight(e.target);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target, 3);
                }}
                onClick={(e) => setRoofMaterial(e.target, 3)}
              />
              <p
                class="textOverImage"
                onMouseEnter={(e) => {
                  setHighlight(e.target.previousSibling);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target.previousSibling, 3);
                }}
                onClick={(e) => setRoofMaterial(e.target.previousSibling, 3)}
              >
                {t("configurator_question1option3")}
              </p>
            </div>
            <div class="col-6 col-md-4 imageContainer d-flex align-items-center smallMarginBottom">
              <img
                class="configuratorImage"
                alt="riet"
                src={require("../images/thatched.webp")}
                onMouseEnter={(e) => {
                  setHighlight(e.target);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target, 4);
                }}
                onClick={(e) => setRoofMaterial(e.target, 4)}
              />
              <p
                class="textOverImage"
                onMouseEnter={(e) => {
                  setHighlight(e.target.previousSibling);
                }}
                onMouseLeave={(e) => {
                  removeSelected(e.target.previousSibling, 4);
                }}
                onClick={(e) => setRoofMaterial(e.target.previousSibling, 4)}
              >
                {t("configurator_question1option4")}
              </p>
            </div>
          </div>
          {previousMaterial === null && questionAnswered === 1 && (
            <div class="alert alert-danger" role="alert">
              {t("configurator_question1reminder")}
            </div>
          )}
        </div>
      </div>
      <div class="row smallMarginBottom">
        <div class="col">
          <button class="configuratorButton btn btn-outline-success" onClick={() => onPreviousClick()}>
            {t("back")}
          </button>
        </div>
        <div class="col">
          <button class="configuratorButton btn btn-success" onClick={() => onAnswerQuestion()}>
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
}
