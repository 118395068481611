import React from "react";
import Icon from "../images/Icon";
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
   return(
    <div id="footer" class="container-fluid greyContainer containerPadding">  
      <div class="row text-center">
        <div class="col-12 col-md-3 marginBottom">
            <Icon /><br/>
            <span class="smallMarginTop">{t('footer_companyname')}</span>
            <address>
              {t('footer_address1')}<br/>
              {t('footer_address2')}
            </address>
        </div>
        <div class="col-12 col-md-3 marginBottom">
            <span class='bold fontsize15'>{t('footer_clients')}</span><br/>
            <a class="whiteColor" href="/solutions">{t('footer_solarpanels')}</a><br/>
            <a class="whiteColor" href="/faq">{t('footer_faq')}</a><br/>
        </div>
        <div class="col-12 col-md-3 marginBottom">
            <span class='bold fontsize15'>{t('footer_professionals')}</span><br/>
            <a class="whiteColor" href={window.location.origin + "/contact"}>{t('footer_contact')}</a>
        </div>
        <div class="col-12 col-md-3">
            <a class="marginTop whiteColor" href={window.location.origin + "/legal#conditions"}>{t('footer_conditions')}</a><br/>
            <a class="marginTop whiteColor" href={window.location.origin + "/legal#privacy"}>{t('footer_privacy')}</a>
            <p class="marginTop">
              <a class="fab fa-facebook whiteColor" href="https://www.facebook.com/people/Solar-Switch/100092834117277/" target="_blank"><span class="normalFont">&nbsp;{t("result_facebook")}</span></a>
              </p>
            <p>
              <a class="fab fa-instagram whiteColor" href="https://www.instagram.com/solarswitch.be/" target="_blank"><span class="normalFont">&nbsp;{t("result_instagram")}</span></a>
            </p>
        </div>
      </div>
      <div class="row marginTop">
        <div class="col text-center">
          <p>Copyright &copy;2022-{new Date().getFullYear()} {t('footer_companyname')} <br/> {t('footer_rightsreserved')}</p>
        </div>
      </div>
    </div>
  );
}