import React from "react";
import { useTranslation } from 'react-i18next';

export default function Legal() {
  const { t } = useTranslation();


  return (
    <div class="container-fluid treesBackgroundImage">
      <div class="container solutionsContainer">
        <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
          <h3>Privacyverklaring</h3>
          <p>Deze privacyverklaring beschrijft de manier waarop SOLAR SWITCH uw persoonsgegevens verwerkt.
          SOLAR SWITCH verwerkt uw persoonsgegevens in overeenstemming met de Algemene Verordening Gegevensbescherming afgekort als “AVG” of beter gekend als “GDPR” en de wet van 30 juli 2018 betreffende de bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens.
          De verklaring is van toepassing indien u:</p>
          <p>-De website van SOLAR SWITCH bezoekt;</p>
          <p>-SOLAR SWITCH uw persoonsgegevens verstrekt
          Gebruik maakt van de diensten van SOLAR SWITCH</p>
          <p>-Op een andere manier contact opneemt met SOLAR SWITCH, anders dan het gebruik van de website.</p>
          <p>-Onderstaande privacy verklaring kan op elk moment worden gewijzigd door SOLAR SWITCH. Een regelmatige controle van de website laat u toe de aanpassingen te bekijken.</p>
          
          <h5>Wie is de verwerkingsverantwoordelijke?</h5>
          <p>De Besloten Vennootschap SOLAR SWITCH, met maatschappelijke zetel te 8501 KOTRIJK, Hof ter Melle(Heu) 44 en met ondernemingsnummer 0795.364.663 is verantwoordelijk voor de verwerking van de persoonsgegevens die worden verwerkt in het kader van de uitvoering van de bedrijvigheid.
          De verantwoordelijke kan worden gecontacteerd op:</p>
          <p>info@solar-switch.eu</p>
          <p>+32456651937</p>
          <p>https://www.solar-switch.eu/home#contactus</p>

          <h5>Welke gegevens verwerkt SOLAR SWITCH?</h5>
          <h6>Persoonsgegevens van klanten</h6>
          <p>In het kader van de dienstverlening en activiteiten van SOLAR SWITCH worden volgende categorieën van persoonsgegevens verwerkt:
          identificatiegegevens (bv. naam, voornaam, geboortedatum)
          contactgegevens (bv. adres, telefoonnummer, e-mail)
          bankgegevens (bv. rekeningnummer)
          Indien van toepassing, gegevens van contractanten, leveranciers en onderaannemers
          SOLAR SWITCH verzamelt en verwerkt de identiteits-, betalings-, professionele en contactgegevens van coördinatoren, contractanten, leveranciers en onderaannemers, evenals hun eventuele (sub)onderaannemer(s), hun personeel, medewerkers, aangestelden en andere nuttige contactpersonen.

          SOLAR SWITCH verzamelt en verwerkt de persoonsgegevens van werknemers in het kader van het personeelsbeheer en de loonadministratie.</p>
          
          <h6>Bezoek website/telefoon/andere kanalen</h6>
          <p>SOLAR SWITCH verwerkt persoonsgegevens via het contactformulier of via Google tracking systemen op de website.
          Uw persoonsgegevens, waarover SOLAR SWITCH beschikt, kunnen worden verwerkt in het kader van een telefoongesprek met u, voor de uitoefening van een efficiënte bedrijfsvoering.</p>

          <h5>Hoe worden uw gegevens beveiligd?</h5>
          <p>Om de veiligheid en de vertrouwelijkheid van uw persoonsgegevens te waarborgen, heeft SOLAR SWITCH verschillende technische en organisatorische maatregelen genomen.
          De toegang tot uw persoonsgegevens is strikt beperkt tot de personeelsleden die hiervoor gemachtigd zijn en beperkt tot de gegevens die nodig zijn voor de uitvoering van de taken van deze personeelsleden. 
          In geen geval kan SOLAR SWITCH aansprakelijk worden geacht voor enige directe of indirecte schade die voortvloeit uit een foutief of onrechtmatig gebruik door een derde van de persoonsgegevens.</p>

          <h5> Wie zijn de ontvangers van uw gegevens?</h5>
          <p>SOLAR SWITCH verwerkt uw persoonsgegevens uitsluitend voor haarzelf en voor eigen intern gebruik. Uw persoonsgegevens zullen niet worden verkocht, doorgegeven of gecommuniceerd worden aan derde partijen.</p>

          <h5>Hoe lang worden uw gegevens bewaard?</h5>
          <p>Uw persoonsgegevens worden bewaard voor de duur van de verwerking die nodig is voor de uitvoering van de dienst die SOLAR SWITCH levert.</p>

          <h5>Wat zijn uw rechten?</h5>
          <p>U heeft het recht om op elk moment gratis kennis te nemen van uw persoonsgegevens, deze te verbeteren, te wijzigen, te verwijderen, te beperken  of over te dragen en dit door verzending van een e-mail aan e-mailadres, mits bijvoeging van een kopie van uw identiteitskaart.
          U heeft het recht zich te verzetten tegen de verwerking van uw persoonsgegevens.
          De gegevens worden meegedeeld, aangepast, verwijderd of overgedragen in de mate van het mogelijke en naargelang de technische mogelijkheden het toelaten. Uw aanvraag zal zo snel mogelijk worden behandeld en binnen een redelijke termijn worden behandeld conform de Algemene Verordening Gegevensbescherming. 
          Er zal zoveel als mogelijk aan uw vraag worden voldaan, behalve als dit door het belang van de onderneming of door een wettelijke verplichting niet mogelijk blijkt te zijn.
          Het staat u geheel vrij om uw persoonsgegevens al dan niet aan SOLAR SWITCH mee te delen. U dient wel te weten dat bepaalde diensten geweigerd kunnen worden indien u uw persoonsgegevens niet invult op bepaalde documenten en dat u als enige verantwoordelijk bent voor de juistheid van de gegevens die u meedeelt aan SOLAR SWITCH.</p>


          <h5>Klachten</h5>
          <p>Indien u een klacht hebt omtrent de verwerking van uw persoonsgegevens en u meent dat uw rechten zijn geschonden, kan u steeds contact opnemen SOLAR SWITCH en een eenvoudig verzoek richten aan info@solar-switch.eu
          Onverminderd andere mogelijkheden van administratief beroep of een voorziening in rechte voor de bevoegde administratieve en gerechtelijke instanties, heeft u het recht om een klacht in te dienen bij de Gegevensbeschermingsautoriteit, indien u van mening bent dat uw rechten niet worden gerespecteerd of dat een verwerking van op u betreffende persoonsgegevens een inbreuk vormt op de Algemene Verordening Gegevensbescherming. 
          Om een klacht in te dienen, kunt u een aanvraag richten tot: 
          Gegevensbeschermingsautoriteit
          Drukpersstraat 35
          1000 Brussel
          E-mail: contact@apd-gba.be</p>

  
          <h5>Aanvaarding</h5>
          <p>Door de website te bezoeken aanvaardt u alle bepalingen van deze Privacy verklaring en stemt u ermee in dat SOLAR SWITCH uw persoonsgegevens verzamelt en verwerkt in overeenstemming met deze Privacy verklaring.</p>

          <h5>Toepasselijk recht en bevoegdheid</h5>
          <p>Deze Privacy verklaring wordt beheerst, geïnterpreteerd en uitgevoerd in overeenstemming met het Belgische recht, dat exclusief van toepassing is bij elk eventueel geschil.
          In het geval van een geschil zijn de rechtbanken van het gerechtelijk arrondissement West-Vlaanderen, afdeling KORTRIJK bevoegd.</p>
        </div>
      </div>
    </div>
  );
}