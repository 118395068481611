import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Calculator() {
  const { t } = useTranslation();
  const [consumption, setConsumption] = useState(30000);
  const [numberOfPanels, setNumberOfPanels] = useState(16);
  const [electricityCost, setElectricityCost] = useState(40);
  const [subsidy, setSubsidy] = useState(30);
  const [cashback, setCashback] = useState(true);
  const [simplify, setSimplify] = useState(false);
  const batteryTowers = Math.round(consumption / 365 / 3.55 / 4) > 12 ? 12 
  : Math.round(consumption / 365 / 3.55 / 4);
  const inverterCost = batteryTowers > 6 ? 3500 : 2500;
  const totalCost = batteryTowers * 8900 + inverterCost + 350;
  const yearlyProfit = 250*batteryTowers * 4 * 3.55*electricityCost/100;
  const investmentDeduction = (totalCost-350)*1.205/4;
  const subsidyDeduction =(totalCost - 350) * subsidy/100;
  const cashbackDeduction = (totalCost - 350) * 0.1;

  return (
    <div>
      <div class="container-fluid treesBackgroundImage">
        <div class="container solutionsContainer">
          <div class="whiteBackground roundedCard darkText containerPadding smallMarginBottom">
          <div class="row">
            <div class="col-12">
                <h2>{t("batterycalculator_title")}</h2>
                <p class="darkText">{t("batterycalculator_subtitle")}</p>
              </div>
              <div class="col-sm-12 col-md-4 smallMarginBottom">
                <div class="form-outline">
                  <label class="form-label" for="typeNumber">{t("batterycalculator_selectpower")}</label>
                  <input type="number" id="typeNumber" class="form-control" defaultValue={consumption} 
                  onChange={(e) => {setConsumption(parseInt(e.target.value));}} />
                </div>
              </div>
              <div class="col-sm-12 col-md-4 smallMarginBottom">
                <div class="form-outline">
                  <label class="form-label" for="typeNumber">{t("batterycalculator_selectsubsidy")}</label>
                  <input type="number" id="typeNumber" class="form-control" defaultValue={subsidy}
                  onChange={(e) => {setSubsidy(parseInt(e.target.value));}}
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-4 smallMarginBottom">
                <div class="form-outline">
                  <label class="form-label" for="typeNumber">{t("batterycalculator_setelectricitycost")}</label>
                  <input type="number" id="typeNumber" class="form-control" step="1" defaultValue={electricityCost}
                  onChange={(e) => {setElectricityCost(parseInt(e.target.value));}}
                  />
                </div>
              </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-4 marginBottom">
              <div class="form-check">
                <label class="form-label" for="typeNumber">{t("batterycalculator_includecashback")}</label>
                <input class="form-check-input" type="checkbox" value="" id="includecashback"
                onClick={(e) => {setCashback(e.target.checked)}}
                defaultChecked></input>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 marginBottom">
              <div class="form-check">
                <label class="form-label" for="typeNumber">{t("batterycalculator_simplify")}</label>
                <input class="form-check-input" type="checkbox" value="" id="simplify"
                onClick={(e) => {setSimplify(e.target.checked)}}></input>
              </div>
            </div>
          </div>
            
            <div class="col-12">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th scope="row">{t("batterycalculator_selectpower")}</th>
                      <td>{consumption}kWh</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("batterycalculator_powerperday")}</th>
                      <td>{Math.round(consumption / 365)}kWh</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("batterycalculator_numberofbatteries")}</th>
                      <td>{Math.round(consumption / 365 / 3.55)}</td>
                    </tr>
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_numberofbatterytowers")}</th>
                      <td>{batteryTowers}</td>
                    </tr>)}
                    <tr>
                      <th scope="row">{t("batterycalculator_batterypower")}</th>
                      <td>{Math.round(batteryTowers * 4 * 3.55)}kWh</td>
                    </tr>
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_towercost")}</th>
                      <td>€8900</td>
                    </tr>)}
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_invertercost")}</th>
                      <td>€{inverterCost}</td>
                    </tr>)}
                    <tr class="table-dark">
                      <th scope="row">{t("batterycalculator_totalcost")}</th>
                      <td>€{totalCost}</td>
                    </tr>
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_loadcycles")}</th>
                      <td>250</td>
                    </tr>)}
                    <tr class="table-success">
                      <th scope="row">{t("batterycalculator_profit_investment_deduction")}</th>
                      <td>€{-1 * Math.round(investmentDeduction)}</td>
                    </tr>
                    <tr class="table-success">
                      <th scope="row">{t("batterycalculator_profit_subsidy")}</th>
                      <td>€{-1 * Math.round(subsidyDeduction)}</td>
                    </tr>
                    {cashback && (<tr class="table-success">
                      <th scope="row">{t("batterycalculator_cashback")}</th>
                      <td>€{-1 * Math.round(cashbackDeduction)}</td>
                    </tr>)}
                    <tr class="table-success">
                      <th scope="row">{t("batterycalculator_totalprofit")}</th>
                      <td>€{-1 * Math.round(investmentDeduction + subsidyDeduction + (cashback ? cashbackDeduction : 0))}</td>
                    </tr>
                    <tr class="table-dark">
                      <th scope="row">{t("batterycalculator_actualinvestment")}</th>
                      <td>€{Math.round(totalCost - investmentDeduction - subsidyDeduction - (cashback ? cashbackDeduction : 0))}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t("batterycalculator_profitperyear")}</th>
                      <td>€{yearlyProfit}</td>
                    </tr>
                    <tr class="table-dark">
                      <th scope="row">{t("batterycalculator_actualpaybacktime")}</th>
                      <td>{Math.round(((totalCost - investmentDeduction - subsidyDeduction - (cashback ? cashbackDeduction : 0)) / yearlyProfit) * 100) / 100}</td>
                    </tr>
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_paybacktimewithoutcashback")}</th>
                      <td>{Math.round(((totalCost - investmentDeduction - subsidyDeduction) / yearlyProfit) * 100) / 100}</td>
                    </tr>)}
                    {!simplify && (
                    <tr>
                      <th scope="row">{t("batterycalculator_paybacktimewithoutcashbackandsubsidy")}</th>
                      <td>{Math.round(((totalCost - investmentDeduction) / yearlyProfit) * 100) / 100}</td>
                    </tr>)}
                    {!simplify && (<tr>
                      <th scope="row">{t("batterycalculator_paybacktime_nohelp")}</th>
                      <td>{Math.round(totalCost / yearlyProfit * 100) / 100}</td>
                    </tr>)}

                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
