import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      back: "Back",
      kWh: "kWh",
      ok: "OK",
      yes: "Yes",
      no: "No",

      notfound_title: "Oops!",
      notfound_description: "This page was not found.",
      notfound_suggestion: "Maybe the hyperlinks in the navigation header can help you further?",

      nav_aboutus: "About us",
      nav_home: "Home",
      nav_solutions: "Solutions",
      "nav_50-50": "Fast profit solution",
      nav_no_costs: "Solar panels without starting capital",
      nav_traditional: "Traditional installation",
      nav_battery: "Battery & Charge Point",
      nav_prices: "Prices",
      nav_products: "Products",
      nav_service: "Service",
      nav_result: "Our work",
      nav_faq: "Frequently Asked Questions",
      nav_contactus: "Contact Us",
      nav_dutch: "Dutch",
      nav_french: "French",
      nav_german: "German",
      nav_english: "English",

      home_title: "Quality solar panels at the best price?",
      home_subtitle: "You can find them here.",
      home_solutionlinks: "View our energy solutions.",
      home_ordertitle: "Your proposal in 1 minute",
      home_ordersubtitle: "Curious about your price? Start your personal order, free and without obligation!",
      home_orderaddress1: "Address",
      home_orderstartbutton: "Start simulation",
      home_curious: "Would you like to find out your personal price in less than 3 minutes? Simulate it now!",
      home_50title: "An interesting alternative: a completely free installation.",
      home_50subtitle: "How does it work?",
      home_50paragraph1:
        "Unlike a traditional installation where you have to invest a serious sum of money, we arrange your installation completely free of charge. All possible costs are taken care of and you have the same installation as if you had traditionally purchased it. How do we arrange payment then? The solar energy you consume from the panels during the day is billed by us at €0.18/kWh, which is more than half of what you pay to a traditional energy supplier, for example for the energy you would still use at night.",
      home_50paragraph2:
        "Having solar panels installed without any costs and then see your energy price halved during the day. Isn't it great? Since the solar panels are installed free of charge, they remain our property, this is contractually laid down.",
      home_howlink: "Curious about all the details and concrete examples? Click here!",
      home_ourstory: "Our Story",
      home_ourstory_paragraph1:
        "After 6 years of successful entrepeneurship as electrician and solar panel installer Domus Systems, it was time to expand in 2022. Glenn Huysentruyt and Jen Verstraete join the company. The name is changed to Solar Switch, while specializing further in offering climate-friendly solutions. Our mission is to make energy affordable for everyone and keeping it that way!",
      home_solutiondetails: "Show all details",
      home_solutionstitle: "Our 3 solutions, tailored to your situation.",
      home_solution1title: "Free installation",
      home_solution1description:
        "The installation is placed entirely at our expense and remains the property of Solar Switch, but you will be offered a very competitive fixed energy price. Ideal if there are other plans with your capital!",
      home_solution2title: "Fast profit solution",
      home_solution2description:
        "You pay a fraction of what solar panels would normally cost and you have the shortest payback time of all our solutions. Your investment is optimally aligned with your real-time consumption. Does this sound like music to your ears?",
      home_solution3title: "Traditional installation.",
      home_solution3description:
        "You pay for the complete solar installation yourself and then enjoy a low energy bill. The classic way of installing solar panels requires a large investment cost. As a result, the payback period is longer.",
      home_solutionquality:
        "We use the same quality materials, regardless of your solution preference. Curious for the details? You can find them here.",
      home_battery: "Interested in batteries or charging stations?",
      home_battery_paragraph1: "In addition to installing solar panels, we can also help with other matters, such as installing home batteries or a charging station. Here too you can count on our trusted quality and competitive price. Batteries can be installed during the installation of solar panels or added afterwards if you already have a PV installation. Contact us by email, telephone, on social media or via the contact form for a no-obligation price quote!",
      home_faqtitle: "An answer to your questions",
      home_faqquestion1: "Do panels still benefit from the capacity tariff?",
      home_faqquestion2: "Do you also install batteries at half price?",
      home_faqquestion3: "Where are the lower case letters?",
      home_faqquestion4: "Do you install panels on an asbestos roof?",
      home_faqquestion5: "I already have panels and just want a charging station.",
      home_faqquestion6: "What bounties are left?",
      home_faqquestion7: "How many panels should I install?",
      home_faqquestion8: "How often do I clean my panels?",
      home_faqmore: "And much more... Check out our answers to all frequently asked questions!",
      home_configurator1: "Still unsure?",
      home_configurator2:
        "View the payback time of our solutions in 3 steps, tailored to your personal situation. You will receive a fully detailed quote, without small print!",
      home_configurator3: "Calculate benefit",
      home_contactustitle: "Can we help you further?",
      home_contactus1:
        "Do you have additional questions? No problem! Contact us by email or phone and we'll help you right away.",
      home_contactus: "Contact Us",
      home_contactmodal_pleasecheck: "Please check the box below. Nothing to see? Please reload the page.",
      home_contactmodal_phone_message: "Thank you! You can also contact us at: +32456651937",
      home_contactmodal_email: "Your email *:",
      home_contactmodal_phone: "Your phone number *:",
      home_contactmodal_message: "Your message *:",
      home_contactmodal_close: "Close",
      home_contactmodal_send: "Send",
      home_contactmodal_success: "We received your message and will reply as soon as possible!",
      home_contactmodal_failure: "Something went wrong when sending the email. Please try again?",

      configurator_title: "Configurator",
      configurator_subtitle: "Describe your personal situation.",
      configurator_sorry: "Unfortunately…",
      configurator_notowner:
        "You indicate that you do not own your current residence. We can only work with homeowners to install solar panels. If you are interested in our panels, please ask your landlord to contact us!",
      configurator_question1: "What roofing material do you currently have?",
      configurator_question1option1: "Roof tiles",
      configurator_question1option2: "Natural slate",
      configurator_question1option3: "Flat roof",
      configurator_question1option4: "Other",
      configurator_question1reminder: "Please select a roofing material.",
      configurator_question2:
        "How many solar panels do you want and how many will fit on your roof? We recommend 16 panels for a 5kW inverter.",
      configurator_question2subtitle: "1 panel is approximately 2m².",
      configurator_question2placeholder: "Choose a number.",
      configurator_question2reminder: "Please select some panels.",
      configurator_question2option1: "Less than 6",
      configurator_question2option2: "More than 30",
      configurator_question3: "Do you prefer a traditional solution?",
      configurator_question3option1: "Which installation do you prefer?",
      configurator_question3option2: "Free variant",
      configurator_question3option3: "Fast profit installation",
      configurator_question3more: "Unclear? Read more here",
      configurator_question4: "Are you interested in a battery or charging station?",
      configurator_question5: "Would you prefer to install the panels yourself (DIY)?",
      configurator_question6: "",
      configurator_summary_owner: "I own this building *",
      configurator_summary_email: "Email *",
      configurator_summary_phone: "Phone *",
      configurator_summary_name: "Name *",
      configurator_summary_street: "Street *",
      configurator_summary_number: "No *",
      configurator_summary_extra: "Bus, …",
      configurator_summary_city: "City *",
      configurator_summary_zip: "Zip Code *",
      configurator_summary_reminder: "Please complete all required fields.",
      configurator_summary_captcha_reminder:
        "Please check the 'I'm not a robot' box above. Nothing to see? Please reload the page.",
      configurator_send: "Send",
      configurator_sent: "Sent",
      configurator_sent_ok1:
        "Thank you very much for your request! You will shortly receive a price proposal at the email address you provided: ",
      configurator_sent_ok2:
        ". You did not receive an email? Please check your spam, try again or contact us on +32456651937. See you soon!",
      configurator_summary_send_nok: "There was an error sending your request, please try again later.",

      calculator_subtitle: "Calculate the cost and payback time of each of our solutions:",
      calculator_chosen_solution: "Chosen solution:",
      calculator_chosen_consumption: "Annual consumption:",
      calculator_amount_panels: "Number of panels:",
      calculator_battery: "incl. battery:",
      calculator_title: "Price Calculator",
      calculator_calculate: "Calculate Now",
      calculator_consumption: "Your current consumption:",
      calculator_current_cost: "Estimated average cost per kW:",
      calculator_current_yearcost: "Your current cost / year:",
      calculator_power: "Solar panel power:",
      calculator_our_price: "Our price, incl. 6% taxes:",
      calculator_subsidy: "Subsidy:",
      calculator_final_cost: "Total Cost:",
      calculator_profit_per_year: "Profit per year*:",
      calculator_profit_explanation0:
        "*Profit = number of kWh of energy that is no longer purchased from the grid minus costs for Solar Switch.",
      calculator_profit_explanation50:
        "*Profit = number of kWh of energy that is no longer purchased from the grid = kWp of installation * energy cost of 0.50 euro/kWh * used % of generated energy.",
      calculator_profit_explanation100:
        "*Profit = number of kWh of energy that is no longer purchased from the grid + injection fee received",
      calculator_cost_per_year: "Solar Switch Cost per Year:",
      calculator_payback_time: "Payback time:",
      calculator_no_cost: " (0 euro total cost.)",
      calculator_price_title: "About our prices",
      calculator_price_indicative:
        "Surprised by our low prices? Rightly so! Keep in mind that all prices shown on this page are an indication. This price may be correct, for example if you, among others: have a tiled roof, live near Kortrijk and have 1 large roof section that you wish to provide with panels.",
      calculator_price_indicative2:
        "Would you like to cover several roof parts with solar panels? You have a roof material that is more difficult to process (e.g. natural slate)? You live far from our warehouse, which means that there will be more transport costs? You have shade on your roof throughout the day? These are, among others, factors that can push the price higher. Every situation is different, request a tailor-made quote, without any further obligations!",
      calculator_year: "year.",
      calculator_diy: "I'll install it myself:",
      calculator_power_efficiency1: "In this calculation, 30% of the generated energy is used immediately.",
      calculator_power_efficiency2: "In this calculation, 50% of the generated energy is used immediately.",

      solutions_title: "Our Solutions",
      solutions_subtitle: "Our 3 solutions in full detail.",
      solutions_small_letters: "This is how it works:",
      solutions_50subtitle: "Recoin your investment cost in an unprecedented short time",
      solutions_50paragraph1:
        "with the quick profit solution you get panels at a very competitive rate, depending on your specific situation, up to more than half the price at competitors. This is possible because a contract is signed whereby we agree that Solar Switch will use your roof to install solar panels for a period of 30 years. After installation, you can freely use the energy generated by the panels. When the panels inject into the grid, the compensation is paid to Solar Switch. Due to the low price, it is possible to install more panels with the same budget. As a result, your peak consumption will be absorbed by the panels and your capacity rate will be lower. An additional advantage of a larger installation is that you receive more subsidy. In this way, the payback time is 2.5-4 years, compared to 6-7 years for competitors. Solar Switch receives the injection fee for 30 years, so we are involved in the story. If problems arise, it is in our best interest to resolve them as quickly as possible. We also take care of the insurance of the installation. After 30 years, the panels become your full property and any form of contract with Solar switch ends. From then on, the injection is also for you! At any time you can choose to buy out the contract and take over the installation.",
      solutions_50paragraph2:
        "You can use the installed panels to the full 100% when this is useful. On a monthly basis, your consumption may not exceed half of the total energy generated. We use the other half of the energy to inject it into the grid. If you use more than half of the generated energy on a monthly basis, you will receive an invoice from us. The target price for this consumption is about 20 euro cents per kWh, far below the electricity price you pay when the sun is not shining. Are you wondering in which cases you might receive an invoice? This could be because there is a winter period with a month in which the sun shone very little. Or maybe you have a smaller roof surface, so you cannot put a lot of power on solar panels. However, the most common cause is an increase in your consumption, because there are many reasons for this. Consider, for example, the purchase of an electric car, an electric hob, heat pumps, jacuzzi or swimming pool. Or maybe your family is expanding (partner, children, elderly residents, ...)? We therefore strongly recommend that you take the future into account and over-dimension when you think about installing solar panels. Since the abolition of the revolving counter, the rule applies: the more solar panels on your roof, the better.",
      solutions_50paragraph3: "A summary: ",
      solutions_50_toomuch1: "Extremely low price per kWh --> earned back super fast.",
      solutions_50_toomuch2:
        "Low price = opportunity to invest in a larger installation = lower capacity rate and more subsidy.",
      solutions_50_toomuch3:
        "For 30 years the injection is for Solar Switch --> For 30 years we ourselves benefit from keeping the installation in top shape.",
      solutions_50_toomuch4:
        "Consumption at a given moment can be 100% of the panels, on a monthly basis we want to inject 50% of the energy. Excess consumption is billed.",
      solutions_50_toomuch5: "After 30 years, the installation is completely yours.",
      solutions_50title: "Fast profit solution",
      solutions_50questions: "See all frequently asked questions about our fast profit solution here.",
      solutions_0title: "Free installation.",
      solutions_0subtitle: "No risk to your savings.",
      solutions_0paragraph1:
        "With this solution, your roof is used for a period of 30 years to place solar panels that remain Solar Switch property. However, this is offered free of charge. Since you have no costs, the payback period is 0 years. In exchange, you will be offered a low fixed electricity contract for the energy generated by the panels. We aim to charge €0.19 per kWh consumed, your specific rate will be clearly indicated in your contract. This amount follows the normal index over the years. This price is a lot lower than what you normally pay and price shocks in the energy market such as in 2022 will no longer affect you. The energy consumption when the sun is not shining is not charged by us, you will receive invoices for this from your standard energy supplier. The costs of your yearly energy consumption will decrease significantly.",
      solutions_0questions: "See all frequently asked questions about our free installation here.",
      solutions_100title: "Traditional installation.",
      solutions_100subtitle: "A safe investment that pays off in the long run.",
      solutions_100paragraph1:
        "Everyone knows the traditional form of installing solar panels. The installation costs are higher and must be paid in full and immediately. After that you enjoy free energy during the sun hours and receive a low reimbursement for your injection. Because your injection fee is quite low, the payback time will be a lot longer than with our other solutions.",
      solutions_100paragraph2:
        "The high amount of capital that is invested in your solar panels cannot be used for any other investments. This is undesirable due to inflation. A new kitchen, a new car or any other renovations planned? In times of inflation it is interesting to make these investments sooner rather than later. Inspect your personal situation and think carefully if this form of buying solar panels is the most interesting.",
      solutions_100questions: "View all frequently asked questions about a traditional solar panel installation here.",
      product_title1: "Our chosen materials",
      product_paragraph1:
        "On this page we expand on the most important components in a solar panel installation and the craftsmanship that you can expect from us. Solar Switch strives to offer an extremely good price-quality ratio and sound material is the very first step in this. No one is happy with an inverter that gives frequent problems or panels that cannot withstand a spring storm.",
      product_paragraph2:
        "Solar Switch does not purchase direct products from outside the European Union. Did you know that the requirements for solar panel installations in Belgium are among the strictest in the world? And yet our panels are still a lot more fire-safe than these strict rules, you can read more about this further on this page. Each part of your Solar Switch installation carries a European quality mark and is approved for use in Belgium.",
      product_1_title: "Solar panels",
      product_1_description1:
        "We currently only supply solar panels from the DAS Solar brand. This panel is completely black and generates a maximum of 420W. After careful research, we came to the conclusion that these solar panels offer the best value for money. There are a number of reasons for this.",
      product_1_description2:
        "-To start with, the solar panel is made entirely of glass, where traditional solar panels have a plastic back. By using glass, the panel is very fire-resistant. Most other panels have fire class C, with our panels this is the safest category, fire class A.",
      product_1_description3:
        "-The energy efficiency is excellent. Here, too, the glass plays a role: due to its transparency, the sun's rays that reach and reflect off the roof surface can also be converted into energy. This offers advantages, for example, on flat roofs and roofs with a lighter color of roofing material, but also during a day with less bright sun.",
      product_1_description4:
        "-The panel is lighter than most others and therefore less stressful for the roof construction. Moreover, it is also safer and a lot more pleasant to install.",
      product_1_description5: "-The panel is extremely reliable and has a 25-year product warranty.",
      product_1_description6:
        "-Degradation over time is also very limited, after 30 years the panels still offer 88% of their original capacity. This may not seem like much, but if you know that most solar panels retain less than 75% after 30 years, you will understand that this is exceptional. So when the installation becomes completely yours after 30 years, you still have an excellent return!",
      product_2_title: "Mounting system",
      product_2_description1:
        "Van der Valk has been a leading player in the field of mounting systems for solar panels for many years. We do not take any risks on a crucial component such as this and opt for absolute top quality. There is an additional cost, but this does not outweigh life-threatening situations such as solar panels blown away. Moreover, thanks to the user-friendliness, a large part of the additional cost is earned back during installation because Van der Valk systems are easy and quick to assemble. We therefore have no doubt that we are good with such quality and that our customers will thank us for this. In addition, all visible construction elements are completely black. They fit perfectly with the completely black panels. The invisible parts may have a gray color, as shown in the photo.",
      product_3_title: "Converter",
      product_3_description1:
        "In the field of inverters, there is a good chance that you will find a Solis of 5kW with us, whether or not in a hybrid version. Depending on the specific situation, a different type may be used. Unfortunately, there is currently no inverter that lasts as long as the other components. The harsh reality is that during this lifespan of your solar panel installation the inverter will probably need to be replaced. This is the case with all brands. Fortunately, Solis already gives a 10-year product warranty and these inverters are competitively priced. You benefit from this! The low price is an immediate advantage and if it turns out that the inverter breaks down outside the warranty period, you benefit again. If you have a large installation installed, you may notice 2 inverters of 5kW instead of 1 larger inverter. This is also an advantage: if an inverter breaks down, you have another inverter with which you can temporarily continue at half power. Moreover, the cost of replacement is less than immediately having to buy a large inverter again.",
      product_4_title: "Battery",
      product_4_description1: "We like to work with the Pylontech brand when installing batteries. Once again the excellent price-quality ratio is the deciding factor. An additional advantage is that batteries from this brand are compatible with a large number of inverters. Of course we also offer other brands of batteries. Installation of the battery can be done immediately during the installation of solar panels, in which case a hybrid inverter is installed. It is also possible to additionally install a battery on an existing solar panel installation. This is a so-called retrofit. In that case, a separate inverter will be installed next to your existing inverter. Depending on your situation, a high-voltage or low-voltage battery will be chosen. Contact us for a no-obligation quote tailored to your specific situation.",
      result_title: "Our work",
      result_paragraph:
        "On this page you can find some pictures about recently finished projects, so you have an idea what our final product looks like. If you want more detailed information about the current state of events, feel free to check out or social pages!",
      result_facebook: "Our Facebook page",
      result_instagram: "Our Instagram page",
      service_title: "Our maintenance service",
      service_paragraph:
        "In addition to installing PV installations, we also offer 2 service formulas to further unburden our customers. You can read more about this on this page. All our used materials have a long warranty period and in the event of a defect during the warranty, a free replacement product will be provided. However, all other additional costs will be invoiced. Think of the number of working hours required, transport costs and administrative costs. If you want to be sure that you will no longer incur additional costs, you can opt for our additional services. Of course, you are free not to choose a service contract.",
      service_1_title1: "Service Formula: Full Warranty",
      service_1_description1:
        "Our full warranty service offer costs 75 euros per year. With this formula, all possible additional costs are covered during the warranty periods of the products. No invoices for transport costs, labor costs, administration costs or any other cost. On top, we install a smart device that automatically monitors your generated energy and lets us know directly if there are any problems, so we can come to the rescue immediately and keep your installation in top shape at all times!",
      service_2_title1: "Service formula: all-in",
      service_2_description1:
        "Our all-in service costs 125 euros on an annual basis. You will receive full support for 30 years. If a component has problems, it will be replaced immediately, without further invoices. There are 2 exceptions: batteries are not covered by this formula and we will not intervene if the installation was tampered with.",
      faq_title: "Frequently Asked Questions",
      faq_question_1: "Are solar panels still useful with the capacity tariff?",
      faq_answer_1:
        "Solar panels can help keep capacity tariff costs down. If you have a high consumption during the day (eg at the same time: cooking, using a washing machine or dryer, charging an electric car) then all the energy supplied by your panels is already a reduction of your peak consumption at that time. If you do not want to constantly monitor this yourself, we recommend that you install a smart controller that, for example, interrupts the charging of the car if a cloud hangs in front of the sun. Another option is to install a battery.",
      faq_question_2: "What if I move houses?",
      faq_answer_2:
        "Since the installation is address-bound and cannot be moved, the new owner must take over the contract. If this is not possible, you have to possibility to buy the solar panels or let us remove them. The specific costs for this depend on the chosen installation type and will be mentioned in the contract.",
      faq_question_3: "Do you install panels on an asbestos roof?",
      faq_answer_3:
        "Not at the moment, for safety reasons of our employees. It is illegal to install directly on asbestos and we do not have a permit to remove asbestos. Possibly in the future, although we advise to renovate your roof first.",
      faq_question_4: "How much kW does an installation produce per hour or per day?",
      faq_answer_4:
        "The power at any given time depends entirely on your personal situation. The yield depends on the wind direction of your panels, the slope of your roof, the position of the sun during the specific time of the year, the degree of cloud cover and of course the number of panels you have installed. Contact us for a tailor-made quote and you will receive an attached graph with the annual and monthly averages for your installation at your address.",
      faq_question_5: "I already have panels and just want a charging station and/or battery",
      faq_answer_5:
        "This is possible, in which case we recommend filling out the contact form on the homepage. A personal quote can then be made.",
      faq_question_6: "How many panels should I ideally install?",
      faq_answer_6:
        "We recommend oversizing and if possible installing at least 16 panels (6700 W peak). This will allow you to get the most out of a 5kW inverter and you will be safe for a future with a possible electric car.",
      faq_question_7: "What subsidies can I still get?",
      faq_answer_7:
        "In 2023 you can get a maximum of 750 euros in subsidies, in 2024 no subsidies are present anymore.",
      faq_question_8: "How often do I clean my panels?",
      faq_answer_8:
        "To keep your yield optimal, there is as little dust and dirt as possible on the panels. In practice, we recommend that you wash your panels yearly. More is better.",
      faq_question_9: "Do I get a discount if I install the panels on my own?",
      faq_answer_9:
        "Yes! For DIY enthusiasts we offer a discount of €600 at the time of writing as you save us a great deal of working hours. Please note: we do not take any responsibility towards fire safety and guarantees.",
      faq_question_10: "Where can I find instructions on installing an solar panels myself?",
      faq_answer_10:
        "We're currently working on an extensive tutorial on our website to help DIYers out, so it's coming soon.",
      faq_subtitle50: "About the fast profit solution",
      faq_question_50_1: "Why do the panels remain property of Solar Switch instead of selling them?",
      faq_answer_50_1:
        "This is done to protect ourselves from financial problems. Suppose the panels become the property of the person whose roof they are placed on. If this person goes into debt, creditors can confiscate all the panels. But in our business model we count on 30 years of injection time.",
      faq_question_50_2: "Do you also install batteries at your low prices?",
      faq_answer_50_2:
        "If your installation is large enough, we are willing to also install a battery at our competitive price, if desired.",
      faq_question_50_3: "How many invoices can I expect?",
      faq_answer_50_3:
        "If you consume more than half of the generated solar energy on a monthly basis, we will charge you for the excess energy consumed. This can certainly be the case in a bad winter month. In addition, you will receive a second invoice from your regular energy supplier for all the energy you have taken from the grid.",
      faq_question_50_4: "",
      faq_answer_50_4: "",
      faq_question_50_5: "",
      faq_answer_50_5: "",
      faq_subtitle0: "About the free deal",
      faq_question_0_1: "Do you also install free batteries?",
      faq_answer_0_1:
        "No. Batteries are very expensive to buy and we wouldn't be able to survive financially if we offered them in the free solution.",
      faq_question_0_2: "Does the fixed electricity contract ever end?",
      faq_answer_0_2:
        "Yes. After 30 years the contract expires and the panels become your property. From then on you can enjoy the energy generated by your panels for free. At that moment, you no longer have any form of contract with Solar Switch.",

      footer_companyname: "Solar Switch BV",
      footer_address1: "Hof Ter Melle 44",
      footer_address2: "8501 Heule",
      footer_clients: "Individuals",
      footer_solarpanels: "Solar Panel Solutions",
      footer_faq: "Frequently Asked Questions",
      footer_calculator: "Price Calculator",
      footer_professionals: "Businesses & Professionals",
      footer_contact: "Contact Us",
      footer_conditions: "Terms and Conditions",
      footer_privacy: "Privacy statements",
      footer_rightsreserved: "All rights reserved",
    },
  },
  fr: {
    translation: {
      back: "Retour",
      kWh: "kWh",
      ok: "OK",
      yes: "Oui",
      no: "Non",

      notfound_title: "Oups!",
      notfound_description: "Cette page n'a pas été trouvée.",
      notfound_suggestion: "Peut-être que la barre de navigation peut vous aider davantage ?",

      nav_aboutus: "À propos de nous",
      nav_home: "Accueil",
      nav_solutions: "Solutions",
      "nav_50-50": "Solution de profit rapide.",
      nav_no_costs: "Panneaux solaires sans capital de départ",
      nav_traditional: "Installation traditionnelle",
      nav_battery: "Batterie et borne de recharge",
      nav_prices: "Prix",
      nav_products: "Produits",
      nav_service: "Service",
      nav_result: "Notre travail",
      nav_faq: "Foire aux questions",
      nav_contactus: "Contactez-nous",
      nav_dutch: "Néerlandais",
      nav_french: "Français",
      nav_german: "Allemand",
      nav_english: "Anglais",

      home_title: "Des panneaux solaires de qualité au meilleur prix ?",
      home_subtitle: "Vous pouvez les trouver ici.",
      home_solutionlinks: "Voir nos solutions énergétiques.",
      home_ordertitle: "Votre proposition en 1 minute",
      home_ordersubtitle:
        "Curieux de connaître votre prix ? Démarrez votre commande personnelle, gratuitement et sans engagement !",
      home_orderaddress1: "Adresse",
      home_orderstartbutton: "Simuler maintenant",
      home_curious: "Vous souhaitez connaître votre prix personnel en moins de 3 minutes ? Simulez-le maintenant !",
      home_50title: "Une alternative intéressante : une installation totalement gratuite.",
      home_50subtitle: "Comment ça marche ?",
      home_50paragraph1:
        "Contrairement à une installation traditionnelle où il faut investir une grosse somme d'argent, nous organisons votre installation entièrement gratuitement. Tous les frais éventuels sont pris en charge et vous disposez de la même installation que si vous l'aviez achetée traditionnellement. Comment s'organise-t-on? L'énergie solaire que vous consommez des panneaux pendant la journée est facturée par nous à 0,18 €/kWh, soit plus de la moitié de ce que vous payez à un fournisseur d'énergie traditionnel, par exemple pour l'énergie que vous utiliseriez pendant la nuit .",
      home_50paragraph2:
        "Se faire installer des panneaux solaires sans aucun frais et voir ensuite son prix de l'énergie divisé par deux dans la journée. C'est formidable non ? Les panneaux solaires étant installés gratuitement, ils restent notre propriété, c'est prévu contractuellement.",
      home_howlink: "Curieux de tous les détails et exemples concrets ? Cliquez ici !",
      home_ourstory: "Notre histoire",
      home_ourstory_paragraph1:
        "Après 6 années d'entrepreneuriat réussi en tant qu'électricien et installateur de panneaux solaires 'Domus Systems', il était temps de se développer en 2022. Glenn Huysentruyt et Jen Verstraete rejoignent l'entreprise. Le nom est changé en Solar Switch, tout en se spécialisant davantage dans l'offre de solutions respectueuses du climat. Notre mission est de rendre l'énergie abordable pour tous et de la maintenir ainsi !",
      home_solutiondetails: "Afficher tous les détails",
      home_solutionstitle: "Nos 3 solutions adaptées à votre situation.",
      home_solution1title: "Installation gratuite",
      home_solution1description:
        "L'installation est entièrement prise en charge par nos soins et reste la propriété de Solar Switch, mais nous vous offrons un prix de l'énergie fixe très compétitif. C'est idéal si vous avez d'autres projets pour votre capital !",
      home_solution2title: "Solution de profit rapide",
      home_solution2description:
        "Vous ne payez qu'une fraction de ce que coûteraient normalement les panneaux solaires et vous bénéficiez du délai de récupération le plus court de toutes nos solutions. Votre investissement est parfaitement aligné sur votre consommation en temps réel. Est-ce que cela sonne comme de la musique à vos oreilles ?",
      home_solution3title: "Installation traditionnelle.",
      home_solution3description:
        "Vous payez vous-même l'installation solaire et bénéficiez ensuite d'une faible facture d'énergie. La manière classique d'installer des panneaux solaires nécessite un investissement important. Cela signifie que le temps de récupération est plus longue.",
      home_solutionquality:
        "Nous utilisons partout les mêmes matériaux de haute qualité, quelle que soit la solution choisie. Curieux des détails ? Tu les trouveras ici.",
      home_battery: "Intéressé par des batteries ou des bornes de recharge ?",
      home_battery_paragraph1: "En plus de l’installation de panneaux solaires, nous pouvons également vous aider dans d’autres domaines, comme l’installation de batteries domestiques ou d’une borne de recharge. Ici aussi, vous pouvez compter sur notre qualité fiable et nos prix compétitifs. Les batteries peuvent être installées lors de l'installation des panneaux solaires ou ajoutées ultérieurement si vous disposez déjà d'une installation photovoltaïque. Contactez-nous par email, téléphone, sur les réseaux sociaux ou via le formulaire de contact pour un devis sans engagement !",
      home_faqtitle: "Une réponse à vos questions",
      home_faqquestion1: "Les panneaux bénéficient-ils toujours du tarif de capacité ?",
      home_faqquestion2: "Installez-vous aussi des batteries à moitié prix ?",
      home_faqquestion3: "Où sont les lettres minuscules ?",
      home_faqquestion4: "Installez-vous des panneaux sur un toit en amiante ?",
      home_faqquestion5: "J'ai déjà des panneaux et je veux juste une borne de recharge.",
      home_faqquestion6: "Quelles sont les primes restantes ?",
      home_faqquestion7: "Combien de panneaux dois-je installer ?",
      home_faqquestion8: "À quelle fréquence dois-je nettoyer mes panneaux ?",
      home_faqmore: "Et bien plus encore... Découvrez nos réponses à toutes les questions fréquemment posées !",
      home_configurator1: "Toujours pas sûr ?",
      home_configurator2:
        "Consultez le délai d'amortissement de nos solutions en 3 étapes, adaptées à votre situation personnelle. Vous recevrez un devis entièrement détaillé, sans petits caractères !",
      home_configurator3: "Calculer l'avantage",
      home_contactustitle: "Pouvons-nous vous aider davantage ?",
      home_contactus1:
        "Vous avez des questions supplémentaires ? Pas de problème ! Contactez-nous par e-mail ou par téléphone et nous vous aiderons immédiatement.",
      home_contactus: "Contactez-nous",
      home_contactmodal_pleasecheck: "Veuillez cocher la case ci-dessous. Rien à voir ? Veuillez recharger la page.",
      home_contactmodal_phone_message: "Merci ! Vous pouvez également nous contacter au : +32456651937",
      home_contactmodal_email: "Votre email * :",
      home_contactmodal_phone: "Votre numéro de téléphone * :",
      home_contactmodal_message: "Votre message * :",
      home_contactmodal_close: "Fermer",
      home_contactmodal_send: "Envoyer",
      home_contactmodal_success: "Nous avons bien reçu votre message et vous répondrons dans les plus brefs délais !",
      home_contactmodal_failure: "Une erreur s'est produite lors de l'envoi de l'e-mail. Veuillez réessayer ?",

      configurator_title: "Configurateur",
      configurator_subtitle: "Décrivez votre situation personnelle.",
      configurator_sorry: "Malheureusement…",
      configurator_notowner:
        "Vous indiquez que vous n'êtes pas propriétaire de votre résidence actuelle. Nous ne pouvons travailler qu'avec des propriétaires pour installer des panneaux solaires. Si vous êtes intéressé par nos panneaux, veuillez demander à votre propriétaire de nous contacter !",
      configurator_question1: "Quel matériau de toiture avez-vous actuellement ?",
      configurator_question1option1: "Tuiles",
      configurator_question1option2: "Ardoise naturelle",
      configurator_question1option3: "Toit plat",
      configurator_question1option4: "Autre",
      configurator_question1reminder: "Veuillez sélectionner un matériau de toiture.",
      configurator_question2:
        "Combien de panneaux solaires voulez-vous et combien s'installeront sur votre toit ? Nous recommandons 16 panneaux pour un onduleur de 5 kW.",
      configurator_question2subtitle: "1 panneau fait environ 2m².",
      configurator_question2placeholder: "Choisissez un numéro.",
      configurator_question2reminder: "Veuillez sélectionner quelques panneaux.",
      configurator_question2option1: "Moins de 6",
      configurator_question2option2: "Plus de 30",
      configurator_question3: "Préférez-vous une installation traditionelle ?",
      configurator_question3option1: "Quelle installation préférez-vous ?",
      configurator_question3option2: "Variante gratuite",
      configurator_question3option3: "Solution de profit rapide",
      configurator_question3more: "Pas clair ? En savoir plus ici",
      configurator_question4: "Êtes-vous intéressé par une batterie ou une borne de recharge ?",
      configurator_question5: "Voulez-vous installer les panneaux vous-même (bricolage) ?",
      configurator_question6: "",
      configurator_summary_owner: "Je suis propriétaire de ce bâtiment *",
      configurator_summary_email: "E-mail *",
      configurator_summary_phone: "Téléphone *",
      configurator_summary_name: "Nom *",
      configurator_summary_street: "Rue *",
      configurator_summary_number: "Num.",
      configurator_summary_extra: "Boîte, ...",
      configurator_summary_city: "Ville *",
      configurator_summary_zip: "Code postal *",
      configurator_summary_reminder: "Veuillez remplir tous les champs obligatoires.",
      configurator_summary_captcha_reminder:
        "Veuillez cocher la case 'Je ne suis pas un robot' ci-dessus. Rien à voir ? Veuillez recharger la page.",
      configurator_send: "Envoyer",
      configurator_sent: "Envoyé",
      configurator_sent_ok1:
        "Merci beaucoup pour votre demande ! Vous recevrez sous peu une proposition de prix à l'adresse e-mail que vous avez fournie :",
      configurator_sent_ok2:
        ". Vous n'avez pas reçu d'email ? Merci de vérifier vos spams, réessayez ou contactez nous au +32456651937. A bientôt !",
      configurator_summary_send_nok:
        "Une erreur s'est produite lors de l'envoi de votre demande, veuillez réessayer plus tard.",

      calculator_subtitle: "Calculez le coût et le retour sur investissement de chacune de nos solutions :",
      calculator_chosen_solution: "Solution choisie :",
      calculator_chosen_consumption: "Votre consommation annuelle :",
      calculator_amount_panels: "Nombre de panneaux :",
      calculator_battery: "batterie incluse :",
      calculator_title: "Calculateur de prix",
      calculator_calculate: "Calculer maintenant",
      calculator_consumption: "Votre consommation actuelle :",
      calculator_current_cost: "Estimation du coût moyen par kW :",
      calculator_current_yearcost: "Votre coût actuel / année :",
      calculator_power: "Puissance du panneau solaire :",
      calculator_our_price: "Notre prix TTC 6% d'impôt :",
      calculator_subsidy: "Subvention :",
      calculator_final_cost: "Coût total :",
      calculator_profit_per_year: "Bénéfice par an* :",
      calculator_profit_explanation0:
        "*Bénéfice = nombre de kWh d'énergie qui ne sont plus achetés sur le réseau moins les coûts pour Solar Switch.",
      calculator_profit_explanation50:
        "*Bénéfice = nombre de kWh d'énergie qui ne sont plus achetés au réseau = kWp de l'installation x coût énergétique de 0,50 euro/kWh x % utilisé de l'énergie produite.",
      calculator_profit_explanation100:
        "*Bénéfice = nombre de kWh d'énergie qui n'est plus acheté au réseau + compensation d'injection reçue.",
      calculator_cost_per_year: "Coût de l'interrupteur solaire par an :",
      calculator_payback_time: "Délai de remboursement :",
      calculator_no_cost: " (0 euros de coût total.)",
      calculator_price_title: "À propos de nos prix",
      calculator_price_indicative:
        "Surpris par nos prix bas ? À juste titre ! Gardez à l'esprit que tous les prix affichés sur cette page sont indicatifs. Ce prix peut être correct, par exemple si vous avez, entre autres : un toit en tuiles, habitez près de Kortrijk et avez une grande section de toit que vous souhaitez équiper de panneaux.",
      calculator_price_indicative2:
        "Souhaitez-vous couvrir plusieurs parties de votre toit avec des panneaux solaires ? Avez-vous un matériau de toiture plus difficile à travailler (par exemple, de l'ardoise naturelle) ? Habitez-vous loin de notre entrepôt, ce qui entraînera des frais de transport plus élevés ? Avez-vous de l'ombre sur votre toit toute la journée ? Ce sont, entre autres, des facteurs qui peuvent faire augmenter le prix. Chaque situation est différente, demandez un devis sur mesure, sans aucune obligation !",
      calculator_year: "année.",
      calculator_diy: "Je vais l'installer moi-même :",
      calculator_power_efficiency1: "Dans ce calcul, 30 % de l'énergie générée est utilisée immédiatement.",
      calculator_power_efficiency2: "Dans ce calcul, 50 % de l'énergie générée est utilisée immédiatement.",

      solutions_title: "Nos solutions",
      solutions_subtitle: "Nos 3 solutions en détail.",
      solutions_small_letters: "Voilà comment cela fonctionne:",
      solutions_50title: "Solution de profit rapide.",
      solutions_50subtitle: "Récupérez votre coût d'investissement en un temps record",
      solutions_50paragraph1:
        "avec la solution de profit rapide, vous obtenez des panneaux à un tarif très compétitif, en fonction de votre situation spécifique, jusqu'à plus de la moitié du prix chez les concurrents. Cela est possible car un contrat est signé par lequel nous convenons que Solar Switch utilisera votre toit pour installer des panneaux solaires pendant une période de 30 ans. Après l'installation, vous pouvez utiliser librement l'énergie générée par les panneaux. Lorsque les panneaux injectent dans le réseau, la compensation est versée à Solar Switch. En raison du faible prix, il est possible d'installer plus de panneaux avec le même budget. De ce fait, votre pointe de consommation sera absorbée par les panneaux et votre taux de capacité sera plus faible. Un avantage supplémentaire d'une installation plus grande est que vous recevez plus de subventions. De cette manière, le délai de récupération est de 2,5 à 4 ans, contre 6 à 7 ans pour les concurrents. Solar Switch reçoit les frais d'injection pendant 30 ans, nous sommes donc impliqués dans l'histoire. Si des problèmes surviennent, il est dans notre intérêt de les résoudre le plus rapidement possible. Nous prenons également en charge l'assurance de l'installation. Après 30 ans, les panneaux deviennent votre pleine propriété et toute forme de contrat avec Solar switch prend fin. Dès lors, l'injection est aussi pour vous ! A tout moment, vous pouvez choisir de racheter le contrat et de prendre en charge l'installation.",
      solutions_50paragraph2:
        "Vous pouvez utiliser les panneaux installés à 100 % lorsque cela est utile. Sur une base mensuelle, votre consommation ne peut excéder la moitié de l'énergie totale générée. Nous utilisons l'autre moitié de l'énergie pour l'injecter dans le réseau. Si vous utilisez plus de la moitié de l'énergie produite sur une base mensuelle, vous recevrez une facture de notre part. Le prix cible pour cette consommation est d'environ 20 centimes d'euro par kWh, bien en deçà du prix de l'électricité que vous payez lorsque le soleil ne brille pas. Vous vous demandez dans quels cas vous pourriez recevoir une facture ? Cela pourrait être dû au fait qu'il y a une période hivernale avec un mois où le soleil a très peu brillé. Ou peut-être avez-vous une surface de toit plus petite, vous ne pouvez donc pas mettre beaucoup d'énergie sur les panneaux solaires. Cependant, la cause la plus fréquente est une augmentation de votre consommation, car il existe de nombreuses raisons à cela. Pensez par exemple à l'achat d'une voiture électrique, d'une plaque de cuisson électrique, de pompes à chaleur, d'un jacuzzi ou d'une piscine. Ou peut-être que votre famille s'agrandit (concubin, enfants, personnes âgées, ...) ? Nous vous recommandons donc fortement de prendre en compte l'avenir et de surdimensionner lorsque vous envisagez d'installer des panneaux solaires. Depuis la suppression du guichet tournant, la règle s'applique : plus il y a de panneaux solaires sur votre toit, mieux c'est.",
      solutions_50paragraph3: "Un petit résumé :",
      solutions_50_toomuch1: "Prix ​​extrêmement bas par kWh --> récupéré très rapidement.",
      solutions_50_toomuch2:
        "Prix ​​bas = opportunité d'investir dans une installation plus grande = taux de capacité inférieur et plus de subventions.",
      solutions_50_toomuch3:
        "Depuis 30 ans, l'injection est pour Solar Switch --> Depuis 30 ans, nous bénéficions nous-mêmes du maintien de l'installation en parfait état.",
      solutions_50_toomuch4:
        "La consommation sur un instantané peut être de 100% des panneaux, sur une base mensuelle on veut injecter 50% de l'énergie. Le dépassement de consommation est facturé.",
      solutions_50_toomuch5: "Après 30 ans, l'installation vous appartient entièrement.",
      solutions_50questions:
        "Consultez toutes les questions fréquemment posées sur notre solution de profit rapide ici.",
      solutions_0title: "Installation gratuite",
      solutions_0subtitle: "Aucun risque pour vos économies.",
      solutions_0paragraph1:
        "Avec cette solution, votre toit est utilisé pendant une période de 30 ans pour installer des panneaux solaires qui restent la propriété de Solar Switch. Cependant, cela est offert gratuitement. Étant donné que vous n'avez aucun coût, le temps de récupération est de 0 ans. En échange, nous vous proposerons un contrat d'électricité fixe à un tarif bas pour l'énergie générée par les panneaux. Nous visons à facturer 0,19 € par kWh consommé, votre tarif spécifique sera clairement indiqué dans votre contrat. Ce montant suit l'indice normal au fil des ans. Ce prix est beaucoup plus bas que ce que vous payez normalement et les chocs de prix sur le marché de l'énergie tels qu'en 2022 ne vous affecteront plus. La consommation d'énergie lorsque le soleil ne brille pas n'est pas facturée par nous, vous recevrez des factures pour cela de votre fournisseur d'énergie standard. Les coûts de votre consommation d'énergie annuelle diminueront considérablement.",
      solutions_0questions: "Consultez ici toutes les questions fréquemment posées sur notre installation gratuite.",
      solutions_100title: "Installation traditionnelle.",
      solutions_100subtitle: "Un investissement sûr et rentable à long terme.",
      solutions_100paragraph1:
        "Tout le monde connaît la forme traditionnelle d'installation de panneaux solaires. Les frais d'installation sont plus élevés et doivent être payés en totalité et immédiatement. Après cela, vous profitez d'une énergie gratuite pendant les heures d'ensoleillement et recevez un faible remboursement pour votre injection. Parce que vos frais d'injection sont assez bas, le temps de récupération sera beaucoup plus long qu'avec nos autres solutions.",
      solutions_100paragraph2:
        "Le montant élevé de capital investi dans vos panneaux solaires ne peut pas être utilisé pour d'autres investissements. Cela est indésirable en raison de l'inflation. Une nouvelle cuisine, une nouvelle voiture ou l'arrivée d'un bébé ? Il est intéressant de pouvoir effectuer ces dépenses immédiatement. Selon vos ressources financières et vos projets d'investissement futurs, cette forme d'achat de panneaux solaires peut être plus ou moins intéressante.",
      solutions_100questions:
        "Consultez ici toutes les questions fréquemment posées sur une installation de panneaux solaires traditionnels.",
      product_title1: "Nos matériaux utilisés",
      product_paragraph1:
        "Sur cette page, nous développons les composants les plus importants d'une installation de panneaux solaires et le savoir-faire que vous pouvez attendre de nous. Solar Switch s'efforce d'offrir un rapport qualité-prix extrêmement bon et le matériel sonore en est la toute première étape. Personne n'est satisfait d'un onduleur qui pose des problèmes fréquents ou de panneaux qui ne peuvent pas résister à une tempête printanière.",
      product_paragraph2:
        "Solar Switch n'achète pas de produits directement en dehors de l'Union européenne. Saviez-vous que les exigences pour les installations de panneaux solaires en Belgique sont parmi les plus strictes au monde ? Et pourtant, nos panneaux sont encore beaucoup plus résistants au feu que ces règles strictes, vous pouvez en savoir plus à ce sujet plus loin sur cette page. Chaque partie de votre installation Solar Switch porte un label de qualité européen et est homologuée pour une utilisation en Belgique.",
      product_1_title: "Panneaux solaires",
      product_1_description1:
        "Nous ne fournissons actuellement que des panneaux solaires de la marque DAS Solar. Ce panneau est entièrement noir et génère un maximum de 420W. Après des recherches approfondies, nous sommes arrivés à la conclusion que ces panneaux solaires offrent le meilleur rapport qualité-prix. Il ya un certain nombre de raisons à cela.",
      product_1_description2:
        "-Pour commencer, le panneau solaire est entièrement en verre, là où les panneaux solaires traditionnels ont un dos en plastique. En utilisant du verre, le panneau est très résistant au feu. La plupart des autres panneaux ont la classe de feu C, avec nos panneaux c'est la catégorie la plus sûre, la classe de feu A.",
      product_1_description3:
        "-L'efficacité énergétique est excellente. Ici aussi, le verre joue un rôle : grâce à sa transparence, les rayons du soleil qui atteignent et se reflètent sur la surface du toit peuvent également être convertis en énergie. Cela offre des avantages, par exemple, sur les toits plats et les toits avec une couleur de matériau de toiture plus claire, mais aussi pendant une journée avec un soleil moins brillant.",
      product_1_description4:
        "-Le panneau est plus léger que la plupart des autres et donc moins stressant pour la construction du toit. De plus, il est également plus sûr et beaucoup plus agréable à installer.",
      product_1_description5: "-Le panneau est extrêmement fiable et bénéficie d'une garantie produit de 25 ans.",
      product_1_description6:
        "-La dégradation dans le temps est également très limitée, après 30 ans les panneaux offrent encore 88% de leur capacité d'origine. Cela peut sembler peu, mais si vous savez que la plupart des panneaux solaires retiennent moins de 75% après 30 ans, vous comprendrez que c'est exceptionnel. Ainsi, lorsque l'installation devient complètement vôtre après 30 ans, vous avez toujours un excellent retour !",
      product_2_title: "Système de montage",
      product_2_description1:
        "Van der Valk est depuis de nombreuses années un acteur de premier plan dans le domaine des systèmes de montage pour panneaux solaires. Nous ne prenons aucun risque sur un composant crucial comme celui-ci et optons pour une qualité irréprochable. Il y a un coût supplémentaire, mais cela ne compense pas les situations mettant la vie en danger telles que les panneaux solaires soufflés. De plus, grâce à la convivialité, une grande partie du coût supplémentaire est récupérée lors de l'installation car les systèmes Van der Valk sont faciles et rapides à assembler. Nous ne doutons donc pas que nous soyons bons avec une telle qualité et que nos clients nous en remercieront. De plus, tous les éléments de construction visibles sont entièrement noirs. Ils s'accordent parfaitement avec les panneaux entièrement noirs. Les parties invisibles peuvent avoir une couleur grise, comme le montre la photo.",
      product_3_title: "Onduleur",
      product_3_description1:
        "Dans le domaine des onduleurs, il y a de fortes chances que vous trouviez chez nous un Solis de 5kW, en version hybride ou non. Selon la situation spécifique, un type différent peut être utilisé. Malheureusement, il n'existe actuellement aucun onduleur qui dure aussi longtemps que les autres composants. La dure réalité est que pendant cette durée de vie de votre installation de panneaux solaires, l'onduleur devra probablement être remplacé. C'est le cas de toutes les marques. Heureusement, Solis offre déjà une garantie produit de 10 ans et ces onduleurs sont proposés à des prix compétitifs. Vous en profitez ! Le prix bas est un avantage immédiat et s'il s'avère que l'onduleur tombe en panne en dehors de la période de garantie, vous en profitez à nouveau. Si vous avez une grande installation installée, vous remarquerez peut-être 2 onduleurs de 5kW au lieu d'un onduleur plus gros. C'est aussi un avantage : si un onduleur tombe en panne, vous disposez d'un autre onduleur avec lequel vous pouvez temporairement continuer à mi-puissance. De plus, le coût de remplacement est inférieur à l'achat immédiat d'un gros onduleur.",
      product_4_title: "Batterie",
      product_4_description1: "Nous aimons travailler avec la marque Pylontech lors de l'installation des batteries. Une fois de plus, l'excellent rapport qualité-prix est le facteur décisif. Un avantage supplémentaire est que les batteries de cette marque sont compatibles avec un grand nombre d'onduleurs. Bien entendu, nous proposons également d’autres marques de batteries. L'installation de la batterie peut être effectuée immédiatement lors de l'installation des panneaux solaires, auquel cas un onduleur hybride est installé. Il est également possible d'installer en plus une batterie sur une installation de panneaux solaires existante. C'est ce qu'on appelle une rénovation. Dans ce cas, un onduleur séparé sera installé à côté de votre onduleur existant. Selon votre situation, une batterie haute tension ou basse tension sera choisie. Contactez-nous pour un devis sans engagement adapté à votre situation spécifique.",
      result_title: "Notre travail",
      result_paragraph:
        "Sur cette page, vous trouverez des photos de projets récemment terminés, afin que vous ayez une idée de ce à quoi ressemble notre produit final. Si vous souhaitez des informations plus détaillées sur l'état actuel des événements, n'hésitez pas à consulter nos pages sociales !",
      result_facebook: "Notre page Facebook",
      result_instagram: "Notre page Instagram",
      service_title: "Notre service de maintenance",
      service_paragraph:
        "En plus d'installer des installations photovoltaïques, nous proposons également 2 formules de service pour décharger davantage nos clients. Vous pouvez en savoir plus à ce sujet sur cette page. Tous nos matériaux d'occasion ont une longue période de garantie et en cas de défaut pendant la garantie, un produit de remplacement gratuit vous sera fourni.Toutefois, tous les autres frais supplémentaires seront facturés.Pensez au nombre d'heures de travail nécessaires, aux frais de transport et aux frais administratifs.Si vous voulez être sûr de ne plus avoir à supporter de frais supplémentaires, vous pouvez opter pour nos services complémentaires. Bien entendu, vous êtes libre de ne pas opter pour un contrat de service.",
      service_1_title1: "Formule de service : Garantie complète",
      service_1_description1:
        "Notre offre de service de garantie complète coûte 75 euros par an. Avec cette formule, tous les frais supplémentaires éventuels sont couverts pendant les périodes de garantie des produits. Pas de factures pour les frais de transport, les frais de main-d'œuvre, les frais d'administration ou tout autre coût. En plus , nous installons un appareil intelligent qui surveille automatiquement votre énergie générée et nous informe directement en cas de problème, afin que nous puissions immédiatement venir à la rescousse et maintenir votre installation en parfait état à tout moment !",
      service_2_title1: "Formule de service : tout compris",
      service_2_description1:
        "Notre service tout compris coûte 125 euros sur une base annuelle. Vous bénéficierez d'un support complet pendant 30 ans. Si un composant rencontre des problèmes, il sera remplacé immédiatement, sans autre facture. Il y a 2 exceptions : les batteries ne sont pas couvertes par cette formule et nous n'interviendrons pas si l'installation a été trafiquée.",
      faq_title: "Foire aux questions",
      faq_question_1: "Les panneaux solaires sont-ils toujours utiles avec le tarif de capacité ?",
      faq_answer_1:
        "Les panneaux solaires peuvent aider à réduire les coûts des tarifs de capacité. Si vous avez une forte consommation dans la journée (ex : cuisiner, utiliser un lave-linge ou un sèche-linge, recharger une voiture électrique) alors toute l'énergie fournie par vos panneaux est déjà une réduction de votre pic de consommation à ce moment-là. Si vous ne souhaitez pas surveiller cela en permanence vous-même, nous vous recommandons d'installer un contrôleur intelligent qui, par exemple, interrompt la charge de la voiture si un nuage est suspendu devant le soleil. Une autre option consiste à installer une batterie.",
      faq_question_2: "Et si je déménage ?",
      faq_answer_2:
        "Puisque l'installation est liée à l'adresse et ne peut pas être déplacée, le nouveau propriétaire doit reprendre le contrat. Si cela n'est pas possible, vous avez la possibilité d'acheter les panneaux solaires ou de nous les faire enlever. Les coûts spécifiques pour cela dépendent du type d'installation choisi et seront mentionnés dans le contrat.",
      faq_question_3: "Installez-vous des panneaux sur un toit en amiante ?",
      faq_answer_3: "Non, cela est interdit par la loi et nous n'avons pas de permis de désamiantage. Nous vous conseillons une rénovation de toiture par un spécialiste puis contactez-nous pour une installation de panneaux solaires.",
      faq_question_4: "Combien de kW une installation produit-elle par heure ou par jour ?",
      faq_answer_4:
        "Le pouvoir à un moment donné dépend entièrement de votre situation personnelle. Le rendement dépend de la direction du vent de vos panneaux, de la pente de votre toit, de la position du soleil à une période précise de l'année, du degré de nébulosité et bien sûr du nombre de panneaux que vous avez installés. Contactez-nous pour un devis sur mesure et vous recevrez en pièce jointe un graphique avec les moyennes annuelles et mensuelles de votre installation à votre adresse.",
      faq_answer_5:
        "C'est possible, auquel cas nous vous recommandons de remplir le formulaire de contact sur la page d'accueil. Un devis personnalisé pourra alors être établi.",
      faq_question_6: "Combien de panneaux dois-je idéalement installer ?",
      faq_answer_6:
        "Nous vous recommandons de surdimensionner et si possible d'installer au moins 16 panneaux (6700 Watt crête). Cela vous permettra de tirer le meilleur parti d'un onduleur de 5kW et vous serez en sécurité pour un avenir avec une éventuelle voiture électrique.",
      faq_question_7: "Quelles primes puis-je encore obtenir ?",
      faq_answer_7:
        "En 2023, vous pouvez recevoir un maximum de 750 euros de subventions, en 2024 il n'y a plus de subventions.",
      faq_question_8: "À quelle fréquence dois-je nettoyer mes panneaux ?",
      faq_answer_8:
        "Pour garder votre rendement optimal, il y a le moins de poussière et de saleté possible sur les panneaux. En pratique, nous vous recommandons de laver soigneusement vos panneaux au moins une fois par an. Plus souvent c'est mieux.",
      faq_question_9: "Ai-je droit à une réduction si j'installe ma propre installation ?",
      faq_answer_9:
        "Oui ! Au moment de la rédaction de cet article, nous offrons une remise de 600 € aux bricoleurs car vous nous faites économiser une grande partie des heures de travail. Attention : dans ce cas, nous déclinons toute responsabilité concernant sécurité incendie et garantie",
      faq_question_10: "Où puis-je trouver une explication sur l'installation de panneaux solaires vous-même ?",
      faq_answer_10:
        "Nous travaillons actuellement sur un tutoriel complet sur notre site Web pour aider les bricoleurs, donc il arrive bientôt.",
      faq_subtitle50: "À propos de la solution de profit rapide.",
      faq_question_50_1: "Pourquoi les panneaux restent-ils la propriété de Solar Switch plutôt que d'être vendus ?",
      faq_answer_50_1:
        "Ceci est fait pour nous protéger des problèmes financiers. Supposons que les panneaux deviennent la propriété de la personne sur laquelle ils sont placés. Si cette personne s'endette, les créanciers peuvent confisquer tous les panneaux. Mais dans notre modèle économique nous comptons sur 30 ans de temps d'injection.",
      faq_question_50_2: "Installez-vous également des batteries à vos prix compétitifs ?",
      faq_answer_50_2:
        "Si votre installation est suffisamment grande, nous installerons également une batterie à notre prix compétitif si vous le souhaitez.",
      faq_question_50_3: "Combien de factures puis-je attendre ?",
      faq_answer_50_3:
        "Si vous consommez plus de la moitié de l'énergie solaire générée sur une base mensuelle, nous vous facturerons l'énergie excédentaire consommée. Cela peut certainement être le cas lors d'un mauvais mois d'hiver. De plus, vous recevrez un deuxième facture de votre fournisseur d'énergie habituel pour toute l'énergie que vous avez prélevée sur le réseau.",
      faq_question_50_4: "",
      faq_answer_50_4: "",
      faq_question_50_5: "",
      faq_answer_50_5: "",
      faq_subtitle0: "À propos de l'offre gratuite",
      faq_question_0_1: "Installez-vous également des piles gratuites ?",
      faq_answer_0_1:
        "Non. Les batteries sont très chères à l'achat et nous ne pourrions pas survivre financièrement si nous les proposions dans la solution gratuite.",
      faq_question_0_2: "Est-ce que le contrat d'électricité fixe prend fin ?",
      faq_answer_0_2:
        "Oui. Après 30 ans, le bail expire et les panneaux deviennent votre propriété. Dès lors, vous pouvez profiter gratuitement de l'énergie générée par vos panneaux. Dans ce moment, vous n'avez plus aucune forme de contrat avec Solar Switch.",

      footer_companyname: "Solar Switch BV",
      footer_address1: "Hof Ter Melle 44",
      footer_address2: "8501 Heule",
      footer_clients: "Particuliers",
      footer_solarpanels: "Solutions de panneaux solaires",
      footer_faq: "Foire aux questions",
      footer_calculator: "Calculateur de prix",
      footer_professionals: "Entreprises & Professionnels",
      footer_contact: "Contactez-nous",
      footer_conditions: "Conditions générales",
      footer_privacy: "Déclarations de confidentialité",
      footer_rightsreserved: "Tous droits réservés",
    },
  },
  nl: {
    translation: {
      back: "Terug",
      kWh: "kWh",
      ok: "OK",
      yes: "Ja",
      no: "Nee",

      notfound_title: "Oeps!",
      notfound_description: "Deze pagina werd niet gevonden.",
      notfound_suggestion: "Misschien kunnen de hyperlinks in de navigatiebalk u verder helpen?",

      nav_aboutus: "Over ons",
      nav_home: "Startpagina",
      nav_solutions: "Oplossingen",
      "nav_50-50": "Snelle winst oplossing",
      nav_no_costs: "Zonnepanelen zonder startkapitaal",
      nav_traditional: "Traditionele installatie",
      nav_battery: "Batterij & laadpaal",
      nav_prices: "Prijzen",
      nav_products: "Producten",
      nav_service: "Service",
      nav_result: "Resultaten",
      nav_faq: "Veel gestelde vragen",
      nav_contactus: "Contacteer ons",
      nav_dutch: "Nederlands",
      nav_french: "Frans",
      nav_german: "Duits",
      nav_english: "Engels",

      home_title: "Kwaliteitsvolle zonnepanelen aan de allerscherpste prijs?",
      home_subtitle: "Die vindt u hier.",
      home_solutionlinks: "Bekijk onze energie oplossingen.",
      home_ordertitle: "Uw voorstel in 1 minuut",
      home_ordersubtitle: "Benieuwd naar uw prijs? Start een persoonlijke aanvraag, gratis en vrijblijvend!",
      home_orderaddress1: "Adres",
      home_orderstartbutton: "Simuleer nu",
      home_curious: "Interesse in een persoonlijke prijs? Simuleer deze nu!",
      home_50title: "Een interessant alternatief: een volledig kostenloze installatie.",
      home_50subtitle: "Hoe werkt het?",
      home_50paragraph1:
        "In tegenstelling tot een traditionele installatie waarbij u een serieze som geld moet investeren, regelen wij uw installatie volledig kostenloos. Alle mogelijke kosten worden voor onze rekening genomen en u heeft identiek dezelfde installatie als wanneer u deze traditioneel zou aanschaffen. Hoe regelen we de betaling dan?De zonne-energie die u overdag van de panelen verbruikt worden door ons gefactureerd aan €0.18/kWh. Dit is ruim de helft van wat u betaalt aan een traditionele energieleverancier, bijvoorbeeld voor de energie die u nog 's nachts zou verbruiken.",
      home_50paragraph2:
        "Zonnepanelen laten leggen zonder enige kosten en daarna uw energieprijs overdag meer zien halveren. Zalig toch? Aangezien de zonnepanelen gratis bij u worden geïnstalleerd blijven deze wel onze eigendom, dit wordt contractueel vastgelegd.",
      /*home_50title: "Onze interessantste optie: de snelle winst oplossing.",
      home_50subtitle: "Hoe werkt het?",
      home_50paragraph1:
        "Niemand verbruikt 100% van de opgewekte zonne-energie onmiddellijk. Gemiddeld gaat dit over 30%. De andere 70% van de energie wordt door u als particulier op het net geïnjecteerd, waar u een lage vergoeding voor ontvangt ten opzichte van bedrijven. Of uw energie wordt in een peperdure batterij opgeslagen, waardoor uw terugverdientijd lang is. Hier spelen wij op in.",
      home_50paragraph2:
        "Wij installeren zonnepanelen aan een fractie van de prijs. De panelen blijven eigendom van Solar Switch voor een periode van 30 jaar, maar de opgewekte energie van deze panelen kunt u vrij benutten. Injecteren de panelen, dan is de vergoeding voor ons. Resultaat: uw investeringskost is veel beter afgestemd op wat u echt zult verbruiken. Als gevolg daalt uw terugverdientijd drastisch terwijl u praktisch niks meer betaalt voor uw energie tijdens de zonuren. Een win-win!",*/
      home_howlink: "Benieuwd naar alle details en concrete voorbeelden? Klik hier!",
      home_ourstory: "Ons verhaal",
      home_ourstory_paragraph1:
        "Na 6 jaar succesvol ondernemerschap als elektricien en zonnepaneelinstallateur 'Domus Systems' was het tijd om in 2022 uit te breiden. Glenn Huysentruyt en Jen Verstraete treden toe tot het bedrijf. De naam wordt gewijzigd in Solar Switch, terwijl we ons verder specialiseren in het aanbieden van klimaatvriendelijke oplossingen. Onze missie is om energie betaalbaar te maken voor iedereen en het zo te houden!",
      home_solutiondetails: "Toon alle details",
      home_solutionstitle: "Onze 3 oplossingen, afgestemd op uw situatie.",
      home_solution1title: "Gratis installatie",
      home_solution1description:
        "De installatie gebeurt volledig op onze kosten en blijft eigendom van Solar Switch, maar u krijgt een zeer competitieve vaste energieprijs aangeboden. Ideaal als er nog andere plannen zijn met uw kapitaal!",
      home_solution2title: "Snelle winst oplossing",
      home_solution2description:
        "U betaalt een fractie van wat zonnepanelen normaal zouden kosten en hebt de kortste terugverdientijd van al onze oplossingen. Dit komt omdat uw investering optimaal is afgestemd op uw echte realtime verbruik. Klinkt dit als muziek in de oren?",
      home_solution3title: "Traditionele installatie.",
      home_solution3description:
        "U betaalt de zonne-installatie volledig zelf en geniet hierna van een lage energierekening. De klassieke manier van zonnepanelen installeren vraagt om een grote investeringskost. Hierdoor is de terugverdientijd langer.",
      home_solutionquality:
        "Wij gebruiken overal dezelfde kwaliteitsvolle materialen, ongeacht uw gekozen oplossing. Benieuwd naar de details? U vindt ze hier.",
      home_battery: "Interesse in een thuisbatterij of een laadpaal?",
      home_battery_paragraph1: "Naast het installeren van zonnepanelen kunnen we ook met andere zaken helpen, zoals bijvoorbeeld het plaatsen van thuisbatterijen of een laadpaal. Ook hier kunt u rekenen op onze vertrouwde kwaliteit en scherpe prijs. Batterijen kunnen zowel worden geplaatst tijdens het installeren van zonnepanelen of achteraf worden bijgeplaatst als u al een PV-installatie heeft. Contacteer ons via mail, telefonisch, op sociale media of via het contactformulier voor een vrijblijvende prijsofferte!",
      home_faqtitle: "Een antwoord op uw vragen",
      home_faqquestion1: "Hebben panelen nog nut met het capaciteitstarief?",
      home_faqquestion2: "Installeren jullie ook batterijen aan halve prijs?",
      home_faqquestion3: "Waar staan de kleine letters?",
      home_faqquestion4: "Plaatsen jullie panelen op een asbestdak?",
      home_faqquestion5: "Ik heb al panelen en wil enkel een laadpaal.",
      home_faqquestion6: "Welke premies zijn er nog?",
      home_faqquestion7: "Hoeveel panelen heb ik ideaal gezien?",
      home_faqquestion8: "Hoe vaak reinig ik mijn panelen?",
      home_faqmore: "En nog veel meer... Bekijk onze antwoorden op alle vaak gestelde vragen!",
      home_configurator1: "U twijfelt nog?",
      home_configurator2:
        "Bekijk in 3 stappen de terugverdientijd van onze oplossingen, afgestemd op uw persoonlijke situatie. U ontvangt een volledig gedetailleerde offerte, zonder kleine letters!",
      home_configurator3: "Bereken voordeel",
      home_contactustitle: "Kunnen we u verder helpen?",
      home_contactus1:
        "Heeft u bijkomende vragen? Geen probleem! Contacteer ons per mail of telefoon en wij helpen u meteen verder.",
      home_contactus: "Contacteer ons",
      home_contactmodal_pleasecheck: "Gelieve het vakje hieronder aan te vinken. Niks te zien? Herlaad even de pagina.",
      home_contactmodal_phone_message: "Bedankt! U kunt ons ook contacteren op het nummer: +32456651937",
      home_contactmodal_email: "Uw email *: ",
      home_contactmodal_phone: "Uw telefoonnummer *: ",
      home_contactmodal_message: "Uw bericht *: ",
      home_contactmodal_close: "Sluiten",
      home_contactmodal_send: "Verzenden",
      home_contactmodal_success: "We ontvingen uw bericht en antwoorden zo snel mogelijk!",
      home_contactmodal_failure: "Er liep iets fout bij het versturen van de email. Probeert u nogmaals?",

      configurator_title: "Configurator",
      configurator_subtitle: "Schets uw persoonlijke situatie.",
      configurator_sorry: "Helaas…",
      configurator_notowner:
        "U geeft aan niet de eigenaar te zijn van uw huidige woonplaats. We kunnen enkel samenwerken met huiseigenaren om zonnepanelen te installeren. Heeft u interesse in onze panelen, vraag dan aan uw huisbaas om contact met ons op te nemen!",
      configurator_question1: "Welke dakbedekking heeft u momenteel?",
      configurator_question1option1: "Dakpannen",
      configurator_question1option2: "Natuurleien",
      configurator_question1option3: "Plat dak",
      configurator_question1option4: "Andere",
      configurator_question1reminder: "Gelieve een dakbedekking te selecteren.",
      configurator_question2:
        "Hoeveel zonnepanelen wilt u en passen er maximaal op uw dak? Voor een 5kW omvormer raden wij 16 panelen aan.",
      configurator_question2subtitle: "1 paneel is ongeveer 2m².",
      configurator_question2placeholder: "Kies een aantal.",
      configurator_question2reminder: "Gelieve een aantal panelen te selecteren.",
      configurator_question2option1: "Minder dan 6",
      configurator_question2option2: "Meer dan 30",
      configurator_question3: "Gaat uw voorkeur uit naar een traditionele installatie?",
      configurator_question3option1: "Welke installatie prefereert u?",
      configurator_question3option2: "Gratis variant",
      configurator_question3option3: "Snelle winst oplossing",
      configurator_question3more: "Onduidelijk? Lees hier meer",
      configurator_question4: "Heeft u interesse in een batterij of laadpaal?",
      configurator_question5: "Prefereert u om de installatie volledig zelf te plaatsen?",
      configurator_question6: "Wat is ongeveer uw huidig jaarverbruik?",
      configurator_summary_owner: "Ik ben eigenaar van dit gebouw *",
      configurator_summary_email: "Email *",
      configurator_summary_phone: "Tel.: *",
      configurator_summary_name: "Naam *",
      configurator_summary_street: "Straat *",
      configurator_summary_number: "Nr *",
      configurator_summary_extra: "Bus, …",
      configurator_summary_city: "Stad *",
      configurator_summary_zip: "Postcode *",
      configurator_summary_reminder: "Gelieve alle verplichte velden in te vullen.",
      configurator_summary_captcha_reminder:
        "Gelieve het 'Ik ben geen robot' vakje hierboven aan te vinken. Niks te zien? Herlaad dan even de pagina.",
      configurator_send: "Verzenden",
      configurator_sent: "Verzonden",
      configurator_sent_ok1:
        "Hartelijk bedankt voor uw aanvraag! U ontvangt binnenkort een prijsvoorstel op het door u opgegeven email adres: ",
      configurator_sent_ok2:
        " . Ontvangt u geen mail? Probeer dan even opnieuw of contacteer ons op het nummer +32456651937. Tot snel!",
      configurator_summary_send_nok: "Er liep iets fout bij het versturen van uw aanvraag, probeer later opnieuw aub.",

      calculator_subtitle: "Bereken de kosten en terugverdientijd van elk van onze oplossingen:",
      calculator_chosen_solution: "Gekozen oplossing:",
      calculator_chosen_consumption: "Uw jaarverbruik:",
      calculator_amount_panels: "Aantal panelen:",
      calculator_battery: "incl. batterij:",
      calculator_title: "Prijscalculator",
      calculator_calculate: "Bereken nu",
      calculator_consumption: "Uw huidig verbruik:",
      calculator_current_cost: "Geschatte gemiddelde kostprijs per kW:",
      calculator_current_yearcost: "Uw huidige kost / jaar:",
      calculator_power: "Vermogen zonnepanelen:",
      calculator_our_price: "Onze prijs incl. 6% BTW:",
      calculator_subsidy: "Premie:",
      calculator_final_cost: "Totaalkost:",
      calculator_profit_per_year: "Winst per jaar*:",
      calculator_profit_explanation0:
        "*Winst = aantal kWh energie die niet langer van het net wordt afgenomen verminderd met kosten aan Solar Switch.",
      calculator_profit_explanation50:
        "*Winst = aantal kWh energie die niet langer van het net wordt afgenomen = kWp van installatie x energiekostprijs van 0.50 euro/kWh x benutte % van opgewekte energie.",
      calculator_profit_explanation100:
        "*Winst = aantal kWh energie die niet langer van het net wordt afgenomen + ontvangen injectievergoeding.",
      calculator_cost_per_year: "Kost aan Solar Switch per jaar:",
      calculator_payback_time: "Terugverdientijd:",
      calculator_no_cost: " (0 euro kosten.)",
      calculator_price_title: "Omtrent onze prijzen",
      calculator_price_indicative:
        "Verbaasd door onze lage prijzen? Terecht! Houd er wel rekening mee dat alle getoonde prijzen op deze pagina een indicatie zijn. Mogelijks klopt deze prijs exact, bijvoorbeeld wanneer u onder andere een pannendak heeft, in de buurt van Kortrijk woont en 1 groot dakdeel heeft die u wenst te voorzien van panelen.",
      calculator_price_indicative2:
        "Wenst u meerdere dakdelen te bedekken met zonnepanelen? U heeft een moeilijker te bewerken dakbedekking (bvb. natuurleien)? U woont ver van ons magazijn waardoor er meer verplaatsingskosten zullen zijn? U heeft doorheen de dag schaduw op uw dak? Dit zijn onder andere factoren die de prijs hoger kunnen doen uitvallen. Elke situatie is anders, vraag een op maat gemaakte offerte aan, zonder verdere verplichtingen!",
      calculator_year: "jaar.",
      calculator_diy: "Ik plaats de installatie zelf:",
      calculator_power_efficiency1: "In deze berekening wordt 30% van de opgewekte energie onmiddellijk benut.",
      calculator_power_efficiency2: "In deze berekening wordt 50% van de opgewekte energie onmiddellijk benut.",

      batterycalculator_title: "Batterij calculator",
      batterycalculator_subtitle: "Bereken de efficientie en terugverdientijd van uw eigen installatie",
      batterycalculator_selectpower: "Vermogen zonnepanelen",
      batterycalculator_selectsubsidy: "Subsidie percentage",
      batterycalculator_setelectricitycost: "Elektriciteitskost in eurocent/kWh",
      batterycalculator_includecashback: "Inclusief cashback",
      batterycalculator_simplify: "Vereenvoudigd",
      batterycalculator_powerperday: "Vermogen per dag",
      batterycalculator_numberofbatteries: "Aantal benodigde batterijen",
      batterycalculator_numberofbatterytowers: "Aantal batterij torens",
      batterycalculator_batterypower: "Totaal batterij vermogen",
      batterycalculator_towercost: "Kostprijs per batterijtoren",
      batterycalculator_invertercost: "Kostprijs omvormer",
      batterycalculator_totalcost: "Aanvankelijke kostprijs",
      batterycalculator_loadcycles: "Aantal laadcycli per jaar",
      batterycalculator_profitperyear: "Jaarlijkse besparing",
      batterycalculator_paybacktime_nohelp: "Terugverdientijd zonder cashback, subsidie en investeringsaftrek",
      batterycalculator_profit_investment_deduction: "Bespaarde belastingen door investeringsaftrek",
      batterycalculator_profit_subsidy: "Subsidie bedrag",
      batterycalculator_cashback: "Cashback bedrag",
      batterycalculator_totalprofit: "Totale besparing",
      batterycalculator_actualinvestment: "Netto investering",
      batterycalculator_actualpaybacktime: "Netto terugverdientijd",
      batterycalculator_paybacktimewithoutcashback: "Terugverdientijd zonder cashback",
      batterycalculator_paybacktimewithoutcashbackandsubsidy: "Terugverdientijd zonder cashback en subsidie",

      solutions_title: "Onze oplossingen",
      solutions_subtitle: "Onze 3 oplossingen in volledig detail.",
      solutions_small_letters: "Zo werkt het: ",
      solutions_50title: "Snelle winst oplossing.",
      solutions_50subtitle: "Win uw investeringskost terug in een ongezien korte tijd",
      solutions_50paragraph1:
        "bij de snelle winst oplossing krijgt u panelen aan een enorm scherp tarief, afhankelijk van uw specifieke situatie tot ruim de helft van de prijs bij concurrenten. Dit is mogelijk omdat een contract wordt ondertekend waarbij we overeen komen dat Solar Switch gedurende een periode van 30 jaar uw dak gebruikt om zonnepanelen op te plaatsen. Na installatie kunt u de opgewekte energie van de panelen vrij benutten. Wanneer de panelen injecteren op het net wordt de vergoeding aan Solar Switch uitgekeerd. Door de lage prijs is het mogelijk om meer panelen te leggen met hetzelfde budget. Hierdoor zal uw piekverbruik door de panelen worden opgevangen en zal uw capaciteitstarief lager zijn. Bijkomend voordeel van een grotere installatie is dat u meer subsidie ontvangt. Op deze manier is de terugverdientijd 2,5-4 jaar, in vergelijking met 6-7 jaar bij concurrenten. Gedurende 30 jaar krijgt Solar Switch de injectievergoeding en zijn we dus mee betrokken in het verhaal. Als er problemen optreden hebben we er alle baat bij om deze zo snel mogelijk op te lossen. Ook de verzekering van de installatie nemen we voor onze rekening. Na 30 jaar worden de panelen volledig uw eigendom en stopt elke vorm van contract met Solar switch. Vanaf dan is ook de injectie voor u! Op elk moment kunt u ervoor kiezen het contract af te kopen en de installatie over te nemen.",
      solutions_50paragraph2:
        "De geplaatste panelen kunt u voor de volle 100% benutten op de momenten waar dit nuttig van pas komt. Op maandbasis mag uw verbruik maximaal de helft van de totaal opgewekte energie bedragen. De andere helft van de energie gebruiken wij om in het net te injecteren. Verbruikt u op maandbasis meer dan de helft van de opgewekte energie dan ontvangt u een factuur van ons. Richtprijs voor dit verbruik is ongeveer 20 eurocent per kWh, ver onder de elektriciteitsprijs die u betaalt wanneer de zon niet schijnt. Vraagt u zich af in welke gevallen u mogelijks een factuur krijgt? Dit zou bijvoorbeeld kunnen omdat er een winterperiode is met een maand waarin de zon zeer weinig scheen. Of misschien heeft u een kleiner dakoppervlak waardoor u niet heel veel vermogen aan zonnepanelen kunt leggen. De meest voorkomende oorzaak is echter een stijging in uw verbruik, want hier zijn vele redenen voor. Denk bijvoorbeeld aan de aanschaf van een elektrische wagen, een elektrische kookplaat, warmtepompen, jacuzzi of zwembad. Of misschien breidt uw gezin uit (partner, kinderen, inwonende ouderen, ...)? We raden dan ook ten zeerste aan om rekening te houden met de toekomst en ruim te overdimensioneren wanneer u denkt aan het laten installeren van zonnepanelen. Sinds de afschaffing van de terugdraaiende teller geldt de regel: hoe meer zonnepanelen op uw dak, hoe beter.",
      solutions_50paragraph3: "Een korte samenvatting:",
      solutions_50_toomuch1: "Enorm lage prijs per kWh --> supersnel terug verdiend.",
      solutions_50_toomuch2:
        "Lage prijs = mogelijkheid tot investeren in een grotere installatie = lager capaciteitstarief en meer subsidie.",
      solutions_50_toomuch3:
        "30 jaar lang is de injectie voor Solar Switch --> 30 jaar lang hebben we er zelf alle baat bij de installatie in topvorm te houden.",
      solutions_50_toomuch4:
        "Verbruik bij momentopname mag 100% van de panelen bedragen, op maandbasis willen we 50% van de energie injecteren. Teveel verbruik wordt gefactureerd.",
      solutions_50_toomuch5: "Na 30 jaar is de installatie volledig van u.",
      solutions_50questions: "Bekijk hier alle vaak gestelde vragen omtrent onze snelle winst oplossing.",
      solutions_0title: "Gratis installatie.",
      solutions_0subtitle: "Geen enkel risico voor uw spaarcentjes.",
      solutions_0paragraph1:
        "Ook bij deze oplossing wordt uw dak gedurende 30 jaar gebruikt en voorzien van zonnepanelen. Alleen wordt dit kosteloos aangeboden. Aangezien u geen enkele kost heeft is de terugverdientijd dan ook 0 jaar. In ruil krijgt u een vast elektriciteitscontract aangeboden voor de door de panelen opgewekte energie. Richtprijs hiervoor is €0.20 per verbruikte kWh, een specifieke prijs zal worden vermeld in uw persoonlijke contract. Dit bedrag wordt jaarlijks geïndexeerd. Deze prijs is een stuk lager dan wat u normaal betaalt en prijsschokken in de energiemarkt zoals in 2022 zullen u niet langer deren. De energieafname wanneer de zon niet schijnt wordt niet door ons afgerekend, hiervoor krijgt u facturen van uw standaard energie leverancier. Uw jaarlijkse energiekosten zullen aanzienlijk dalen.",
      solutions_0questions: "Bekijk hier alle vaak gestelde vragen omtrent onze kostenloze installatie.",
      solutions_100title: "Traditionele installatie.",
      solutions_100subtitle: "Een veilige investering die zich op langere termijn terug verdient.",
      solutions_100paragraph1:
        "De traditionele vorm van zonnepanelen plaatsen kent iedereen. De installatiekosten zijn hoger en dienen volledig en onmiddellijk worden betaald. Daarna geniet u van gratis energie tijdens de zonneuren en krijgt u een lage vergoeding voor uw injectie. Doordat uw injectievergoeding vrij laag is zal de terugverdientijd een stuk langer zijn dan met onze andere oplossingen. ",
      solutions_100paragraph2:
        "Het hoge kapitaal dat in de zonnepanelen wordt gestopt kan niet worden gebruikt om andere investeringen mee te doen. Een nieuwe keuken, een nieuwe wagen of andere renovaties gepland? Met de huidige inflatie is het interessant om dergelijke uitgaven onmiddellijk te kunnen doen. Bekijk aandachtig uw persoonlijke situatie en denk na of deze vorm van zonnepanelen kopen al dan niet de meest interessante is.",
      solutions_100questions:
        "Bekijk hier alle vaak gestelde vragen omtrent een traditionele zonnepanelen installatie.",
      product_title1: "Onze gebruikte materialen",
      product_paragraph1:
        "Op deze pagina breiden we uit over de belangrijkste componenten in een zonnepanelen installatie en het vakmanschap dat u hierbij van ons kunt verwachten. Solar Switch streeft ervoor een uiterst goede prijs-kwaliteitsverhouding aan te kunnen bieden en deugdelijk materiaal is hierbij de allereerste stap. Niemand is gelukkig met een omvormer die frequent problemen geeft of panelen die geen voorjaarsstorm kunnen verdragen.",
      product_paragraph2:
        "Solar Switch koopt geen rechtreekse producten aan van buiten de Europese Unie. Wist u dat de eisen voor zonnepaneel installaties in België tot de strengste ter wereld behoren? En toch zijn onze panelen nog een stuk brandveiliger dan deze strenge regels, hierover leest u meer verder op deze pagina. Elk deel van uw Solar Switch installatie draagt een Europees keurmerk en is goedgekeurd voor gebruik in België.",
      product_1_title: "Zonnepanelen",
      product_1_description1:
        "Momenteel leveren wij enkel zonnepanelen van het merk DAS Solar. Dit paneel is volledig zwart en wekt maximaal 420W op. Na zorgvuldig onderzoek kwamen we tot de conclusie dat deze zonnepanelen de beste prijs-kwaliteit bieden. Hier zijn een aantal redenen voor.",
      product_1_description2:
        "-Om te beginnen is het zonnepaneel volledig gemaakt van glas, waar traditionele zonnepanelen een kunststof achterkant hebben. Door gebruik te maken van glas is het paneel zeer brandveilig. De meeste andere panelen hebben brandklasse C, bij onze panelen is dit de veiligste categorie, brandklasse A.",
      product_1_description3:
        "-De energie-efficientië is uitstekend. Ook hier speelt het glas een rol: door de transparantie kunnen zonnestralen die het dakoppervlak bereiken en weerkaatsen ook worden omgezet in energie. Dit biedt bijvoorbeeld voordelen op platte daken en daken met lichtere kleur van dakbedekking, maar ook gewoon tijdens een dag met minder heldere zon.",
      product_1_description4:
        "-Het paneel is lichter dan de meeste andere en dus minder belastend voor de dakconstructie. Bovendien is het ook veiliger en een stuk aangenamer om te installeren.",
      product_1_description5: "-Het paneel is uiterst betrouwbaar en heeft 25 jaar productgarantie.",
      product_1_description6:
        "-Ook de degradatie doorheen de tijd is zeer beperkt, na 30 jaar bieden de panelen nog steeds 88% van hun originele vermogen. Dit lijkt misschien weinig, maar als je weet dat de meeste zonnepanelen na 30 jaar minder dan 75% overhouden dan begrijpt u dat dit uitzonderlijk is. Dus wanneer de installatie na 30 jaar volledig van u wordt, heeft u nog steeds een uitstekend rendement!",
      product_2_title: "Montagesysteem",
      product_2_description1:
        "Van der Valk is al jaar en dag toonaangevende speler op gebied van montagesystemen voor zonnepanelen. Op een cruciale component zoals deze nemen we geen enkel risico en kiezen we voor absolute topkwaliteit. Er is een meerprijs, maar deze weegt totaal niet op tegen levensgevaarlijke situaties zoals wegwaaiende zonnepanelen. Bovendien wordt, dankzij de gebruiksvriendelijkheid, een groot deel van de meerprijs terug verdiend tijdens de installatie omdat Van der Valk systemen makkelijk en snel te monteren zijn. We twijfelen er dan ook niet aan dat we met dergelijke kwaliteit goed zitten en dat onze klanten ons hiervoor dankbaar zullen zijn. Bovendien zijn alle zichtbare constructie elementen volledig zwart. Zo passen deze perfect bij de volledig zwarte panelen. De niet zichtbare delen kunnen een grijze kleur hebben, zoals op de foto.",
      product_3_title: "Omvormer",
      product_3_description1:
        "Op gebied van omvormer is de kans groot dat u bij ons een Solis van 5kW zal aantreffen, al dan niet in hybride uitvoering. Afhankelijk van de specifieke situatie kan een ander type worden gebruikt. Helaas bestaat er momenteel nog geen omvormer die even lang meegaat als de andere componenten. De harde realiteit is dat tijdens deze levensduur uw zonnepaneel installatie de omvormer waarschijnlijk zal moeten worden vervangen. Dit is bij alle merken zo. Gelukkig geeft Solis alvast 10 jaar productgarantie en zijn deze omvormers scherp geprijsd. Hier profiteert u mee van! De lage prijs is een onmiddellijk voordeel en als blijkt dat de omvormer stuk gaat buiten de garantieperiode profiteert u nogmaals. Indien u een grote installatie laat plaatsen zult u mogelijks 2 omvormers merken van 5kW in plaats van 1 grotere omvormer. Ook dit is een voordeel: als een omvormer stuk gaat heeft u nog een andere omvormer waarmee u tijdelijk op halve kracht verder kunt. Bovendien is de kost van vervanging kleiner dan onmiddellijk een grote omvormer te moeten opnieuw aankopen.",
      product_4_title: "Batterij",
      product_4_description1: "Voor het plaatsen van batterijen werken we graag met het merk Pylontech. Opnieuw geeft de uitstekende prijs-kwaliteitsverhouding de doorslag. Een bijkomend voordeel is dat batterijen van dit merk compatibel zijn met een groot aantal omvormers. Uiteraard bieden we ook andere merken van batterijen aan. Installatie van de batterij kan onmiddellijk tijdens het plaatsen van zonnepanelen worden gedaan, in dat geval wordt een hybride omvormer geplaatst. Ook het bijkomend plaatsen van een batterij op een reeds bestaande zonnepanelen installatie is mogelijk. Dit is een zogenaamde retrofit. In dat geval wordt een aparte omvormer naast uw reeds bestaande omvormer geplaatst. Afhankelijk van uw situatie zal voor een high-voltage of low-voltage batterij worden gekozen. Contacteer ons voor een vrijblijvende offerte die op uw specifieke situatie is afgestemd.",
      result_title: "Ons werk",
      result_paragraph:
        "Op deze pagina vindt u enkele foto's van recent afgeronde projecten, zodat u een idee heeft hoe ons eindproduct eruit ziet. Als u nog meer foto's wilt zien van onze meest recente projecten, neem dan gerust een kijkje op onze sociale pagina's!",
      result_facebook: "Onze Facebook-pagina",
      result_instagram: "Onze Instagram-pagina",
      service_title: "Onze onderhoudservice",
      service_paragraph:
        "Naast het installeren van PV installaties bieden we ook 2 service formules aan om onze klanten verder te ontzorgen. Hierover leest u op deze pagina meer. Al onze gebruikte materialen beschikken over een ruime garantieperiode en bij een defect tijdens de garantie zal een gratis vervangend product worden aangeboden. Alle andere bijkomende kosten worden gefactureerd. Denk aan het aantal benodigde arbeidsuren, transportkosten en administratieve kosten. Wilt u zekerheid geen extra kosten meer te hebben kan u opteren voor onze bijkomende services. Uiteraard staat het u vrij om geen service contract af te sluiten.",
      service_1_title1: "Service formule: volledige garantie",
      service_1_description1:
        "Ons 'volledige garantie' service aanbod kost 75 euro op jaarbasis. Met deze formule zijn alle mogelijke extra kosten gedekt gedurende de garantieperiodes van de producten. In geval van een defect wordt altijd een gratis vervangend product aangeboden, maar ook alle andere mogelijke kosten zoals transportkosten, arbeidskosten en administratiekosten zijn volledig voor onze rekening. Bovendien installeren we een slim toestel die automatisch uw opgewekte energie opvolgt en ons rechtstreeks laat weten wanneer er zich problemen voordoen. Zo kunnen we onmiddellijk ter hulp komen en uw installatie op elk moment in topvorm houden!",
      service_2_title1: "Service formule: all-in",
      service_2_description1:
        "Onze all-in formule kost 125 euro op jaarbasis. Hierin krijgt u volledige ondersteuning voor de volle 30 jaar. Heeft een component problemen dan wordt deze onmiddellijk vervangen, zonder verdere facturen. Hierin zijn 2 uitzonderingen: batterijen vallen niet onder deze regeling en ook wanneer er met de installatie werd geknoeid wordt niet tussen beide gekomen.",
      faq_title: "Vaak gestelde vragen",
      faq_question_1: "Hebben zonnepanelen nog nut met het capaciteitstarief?",
      faq_answer_1:
        "Zonnepanelen kunnen helpen om de kosten van het capaciteitstarief laag te houden. Als u overdag een hoog verbruik heeft (bvb tegelijk koken, wasmachine of droogkast, laden van elektrische wagen) dan is alle energie die door uw panelen wordt geleverd alvast een verlaging van uw piekverbruik op dat moment. Wilt u dit niet constant zelf in de gaten moeten houden, dan raden we u aan een slimme sturing te installeren die bvb het laden van de wagen onderbreekt als een wolk voor de zon hangt. Een andere mogelijkheid is het plaatsen van een batterij.",
      faq_question_2: "Wat als ik verhuis?",
      faq_answer_2:
        "Aangezien de installatie aan een adres is gebonden en niet kan worden meegenomen, dient de nieuwe eigenaar het contract over te nemen. Als dit niet mogelijk is, heeft u de mogelijk de panelen over te kopen of door ons te laten verwijderen. Exacte prijzen hiervoor hangen af van specifieke situatie en worden beslist op de moment dat het contract wordt gemaakt.",
      faq_question_3: "Plaatsen jullie panelen op een asbestdak?",
      faq_answer_3: "Neen, dit is bij wet verboden en we hebben geen vergunning voor het verwijderen van asbest. We raden een dakrenovatie aan door een specialist en ons daarna te contacteren voor een installatie van zonnepanelen.",
      faq_question_4: "Hoeveel kW produceert een installatie per uur of per dag?",
      faq_answer_4:
        "Het vermogen op een gegeven moment hangt volledig af van uw persoonlijke situatie. De opbrengst is afhankelijk van de windrichting van uw panelen, de hellingsgraad van uw dak, de stand van de zon gedurende de specifieke tijd van het jaar, de bewolkingsgraad en natuurlijk het aantal panelen dat u laat installeren. Contacteer ons voor een op maat gemaakte offerte en dan ontvangt u in bijlage een grafiek met de jaar- en maandgemiddeldes voor uw installatie op uw adres.",
      faq_question_5: "Ik heb al panelen en wil enkel een laadpaal en/of batterij.",
      faq_answer_5:
        "Dat kan zeker, in dat geval raden we aan om het contactformulier in te vullen op de startpagina. Een persoonlijke offerte kan dan worden opgemaakt.",
      faq_question_6: "Hoeveel panelen heb ik ideaal gezien?",
      faq_answer_6:
        "We raden aan om ruim te overdimensioneren en indien mogelijk minimaal 16 panelen te installeren (6700 Wpiek). Hiermee haalt u het maximum uit een 5kW omvormer en zit u safe voor een toekomst met een mogelijks elektrische wagen.",
      faq_question_7: "Welke premies kan ik nog krijgen?",
      faq_answer_7:
        "In 2023 kunt u maximaal 750 euro aan premies krijgen, in 2024 zijn helaas alle premies op zonnepanelen volledig afgeschaft. Gelukkig zijn de prijzen van zonnepanelen ondertussen zo fel gezakt en de opbrengst zo verbeterd dat zonnepanelen rendabeler zijn dan ooit.",
      faq_question_8: "Hoe vaak reinig ik mijn panelen?",
      faq_answer_8:
        "Om uw opbrengst optimaal te houden ligt er zo weinig mogelijk stof en vuil op de panelen. In praktijk raden we aan uw panelen jaarlijks te wassen. Vaker is beter.",
      faq_question_9: "Krijg ik korting als ik mijn installatie zelf plaats?",
      faq_answer_9:
        "Jazeker! Aan doe-het-zelvers bieden we op het moment van schrijven een korting van €600 aangezien jullie ons een groot deel arbeidsuren uitsparen. Opgelet: in dit geval nemen wij geen verantwoordelijkheid omtrent brandveiligheid en garanties.",
      faq_question_10: "Waar vind ik uitleg over het zelf plaatsen van een installatie?",
      faq_answer_10:
        "We werken momenteel aan een uitgebreide tutorial op onze website om doe-het-zelvers een handje te helpen. Deze komt er dus binnenkort aan.",
      faq_subtitle50: "Omtrent de snelle winst oplossing",
      faq_question_50_1: "Waarom blijven de panelen eigendom van Solar Switch en worden deze niet gewoon verkocht?",
      faq_answer_50_1:
        "Dit wordt gedaan om onszelf te beschermen tegen financiële problemen. Stel: de panelen worden eigendom van de persoon op wiens dak ze worden geplaatst. Indien deze persoon in schulden raakt kunnen schuldeisers alle panelen in beslag nemen. Maar in ons business model rekenen we op 30 jaar injectietijd.",
      faq_question_50_2: "Installeren jullie ook batterijen aan jullie lage prijs?",
      faq_answer_50_2:
        "Als uw installatie groot genoeg is plaatsen we indien gewenst ook een batterij aan onze scherpe prijs.",
      faq_question_50_3: "Hoeveel facturen kan ik verwachten?",
      faq_answer_50_3:
        "Als u meer dan de helft van de opgewekte zonne-energie verbruikt op maandbasis, rekenen we deze aan. In een slechte wintermaand kan dit zeker het geval zijn. Daarnaast ontvangt u een tweede factuur van uw vaste energieleverancier voor alle energie die u heeft verbruikt op momenten zonder voldoende zon.",
      faq_question_50_4: "",
      faq_answer_50_4: "",
      faq_question_50_5: "",
      faq_answer_50_5: "",
      faq_subtitle0: "Omtrent de gratis deal",
      faq_question_0_1: "Installeren jullie ook gratis batterijen?",
      faq_answer_0_1:
        "Neen. Batterijen zijn zeer duur in aanschaf en we zouden het hoofd niet boven water kunnen houden als we deze zouden aanbieden in de gratis oplossing.",
      faq_question_0_2: "Stopt het vaste elektriciteitscontract ooit?",
      faq_answer_0_2:
        "Ja. Na 30 jaar loopt het contract af en worden de panelen uw eigendom. Vanaf dan geniet u gratis van de energie die uw panelen opbrengen. Op dit moment hebt u geen enkele vorm van contract meer met Solar Switch.",

      footer_companyname: "Solar Switch BV",
      footer_address1: "Hof Ter Melle 44",
      footer_address2: "8501 Heule",
      footer_clients: "Particulieren",
      footer_solarpanels: "Zonnepaneel oplossingen",
      footer_faq: "Vaak gestelde vragen",
      footer_calculator: "Prijscalculator",
      footer_professionals: "Bedrijven & professionals",
      footer_contact: "Contacteer ons",
      footer_conditions: "Algemene voorwaarden",
      footer_privacy: "Privacy statements",
      footer_rightsreserved: "Alle rechten voorbehouden",
    },
  },
  de: {
    translation: {
      back: "Zurück",
      kWh: "kWh",
      ok: "Okay",
      yes: "Ja",
      no: "Nein",

      notfound_title: "Ups!",
      notfound_description: "Diese Seite wurde nicht gefunden.",
      notfound_suggestion: "Vielleicht hilft Ihnen die Navigationsleiste weiter?",

      nav_aboutus: "Über uns",
      nav_home: "Zuhause",
      nav_solutions: "Lösungen",
      "nav_50-50": "Schnelles Gewinngeschäft",
      nav_no_costs: "Solarmodule ohne Startkapital",
      nav_traditional: "Traditionelle Installation",
      nav_battery: "Batterie & Ladestation",
      nav_prices: "Preise",
      nav_products: "Produkte",
      nav_service: "Service",
      nav_result: "Ergebnisse",
      nav_faq: "Häufig gestellte Fragen",
      nav_contactus: "Kontaktieren Sie uns",
      nav_dutch: "Niederländisch",
      nav_french: "Französisch",
      nav_german: "Deutsch",
      nav_english: "Englisch",

      home_title: "Hochwertige Solarmodule zum allerbesten Preis?",
      home_subtitle: "Sie können sie hier finden",
      home_solutionlinks: "Sehen Sie sich unsere Energielösungen an.",
      home_ordertitle: "Ihr Vorschlag in 1 Minute",
      home_ordersubtitle:
        "Neugierig auf Ihren Preis? Starten Sie kostenlos und unverbindlich Ihre persönliche Bestellung!",
      home_orderaddress1: "Adresse",
      home_orderstartbutton: "Jetzt simulieren",
      home_curious: "Möchten Sie Ihren persönlichen Preis in weniger als 3 Minuten erfahren? Simulieren Sie es jetzt!",
      home_50title: "Eine interessante Alternative: eine völlig kostenlose Installation.",
      home_50subtitle: "Wie funktioniert es?",
      home_50paragraph1:
        "Im Gegensatz zu einer herkömmlichen Installation, bei der Sie viel Geld investieren müssen, arrangieren wir Ihre Installation völlig kostenlos. Alle möglichen Kosten werden berücksichtigt und Sie erhalten die gleiche Installation, als hätten Sie sie traditionell gekauft. Wie organisieren wir? Dann berechnen wir die Solarenergie, die Sie tagsüber aus den Modulen verbrauchen, mit 0,18 €/kWh, was mehr als der Hälfte dessen entspricht, was Sie an einen herkömmlichen Energieversorger zahlen, beispielsweise für die Energie, die Sie nachts noch verbrauchen würden.",
      home_50paragraph2:
        "Solarmodule kostenlos installieren zu lassen und dann im Laufe des Tages zu sehen, wie sich der Strompreis halbiert. Ist das nicht toll? Da die Solarmodule kostenlos installiert werden, bleiben sie unser Eigentum, das ist vertraglich festgelegt.",
      home_howlink: "Neugierig auf alle Details und konkrete Beispiele? Klicken Sie hier!",
      home_ourstory: "Unsere Geschichte",
      home_ourstory_paragraph1:
        "Nach 6 Jahren erfolgreicher Unternehmensführung als Elektriker und Solaranlageninstallateur 'Domus Systems' war es an der Zeit, sich 2022 zu erweitern. Glenn Huysentruyt und Jen Verstraete treten dem Unternehmen bei. Der Name wird in Solar Switch geändert und wir spezialisieren uns weiterhin auf klimafreundliche Lösungen. Unsere Mission besteht darin, Energie für alle bezahlbar zu machen und es auch so zu halten!",
      home_solutiondetails: "Alle Details anzeigen",
      home_solutionstitle: "Unsere 3 Lösungen, zugeschnitten auf Ihre Situation.",
      home_solution1title: "Kostenlose Installation",
      home_solution1description:
        "Die Installation erfolgt vollständig auf unsere Kosten und bleibt Eigentum von Solar Switch, aber wir bieten Ihnen einen sehr wettbewerbsfähigen Fixpreis für Energie an. Ideal, wenn Sie andere Pläne mit Ihrem Kapital haben!",
      home_solution2title: "Schnelles Gewinngeschäft",
      home_solution2description:
        "Sie zahlen nur einen Bruchteil dessen, was Solaranlagen normalerweise kosten würden, und haben die kürzeste Amortisationszeit aller unserer Lösungen. Ihre Investition ist optimal auf Ihren Echtzeitverbrauch abgestimmt. Klingt das wie Musik in Ihren Ohren?",
      home_solution3title: "Traditionelle Installation.",
      home_solution3description:
        "Sie zahlen die komplette Solarinstallation selbst und profitieren danach von einer niedrigen Stromrechnung. Der klassische Weg der Installation von Solarpanelen erfordert eine hohe Investitionskosten. Dadurch ist die Amortisationszeit länger.",
      home_solutionquality:
        "Wir verwenden überall die gleichen hochwertigen Materialien, unabhängig von der gewählten Lösung. Neugierig auf die Details? Sie finden sie hier.",
      home_battery: "Interesse an Batterien oder Ladestationen?",
      home_battery_paragraph1: "Neben der Installation von Solarmodulen können wir auch bei anderen Angelegenheiten helfen, beispielsweise bei der Installation von Heimbatterien oder einer Ladestation. Auch hier können Sie auf unsere bewährte Qualität und den wettbewerbsfähigen Preis zählen. Batterien können bei der Installation von Solarmodulen installiert oder nachträglich hinzugefügt werden, wenn Sie bereits über eine PV-Anlage verfügen. Kontaktieren Sie uns per E-Mail, Telefon, über soziale Medien oder über das Kontaktformular für ein unverbindliches Preisangebot!",
      home_faqtitle: "Eine Antwort auf Ihre Fragen",
      home_faqquestion1: "Profitieren Panels noch vom Kapazitätstarif?",
      home_faqquestion2: "Bauen Sie auch Batterien zum halben Preis ein?",
      home_faqquestion3: "Wo sind die Kleinbuchstaben?",
      home_faqquestion4: "Installieren Sie Paneele auf einem Asbestdach?",
      home_faqquestion5: "Ich habe bereits Panels und möchte nur eine Ladestation.",
      home_faqquestion6: "Welche Kopfgelder sind übrig?",
      home_faqquestion7: "Wie viele Panels soll ich installieren?",
      home_faqquestion8: "Wie oft reinige ich meine Panels?",
      home_faqmore: "Und vieles mehr... Sehen Sie sich unsere Antworten auf alle häufig gestellten Fragen an!",
      home_configurator1: "Immer noch unsicher?",
      home_configurator2:
        "Sehen Sie sich die Amortisationszeit unserer Lösungen in 3 Schritten an, zugeschnitten auf Ihre persönliche Situation. Sie erhalten ein detailliertes Angebot ohne Kleingedrucktes!",
      home_configurator3: "Vorteil berechnen",
      home_contactustitle: "Können wir Ihnen weiterhelfen?",
      home_contactus1:
        "Haben Sie weitere Fragen? Kein Problem! Kontaktieren Sie uns per E-Mail oder Telefon und wir helfen Ihnen sofort.",
      home_contactus: "Kontaktieren Sie uns",
      home_contactmodal_pleasecheck:
        "Bitte kreuzen Sie das Kästchen unten an. Nichts zu sehen? Bitte laden Sie die Seite neu.",
      home_contactmodal_phone_message: "Danke! Sie können uns auch erreichen unter: +32456651937",
      home_contactmodal_email: "Ihre E-Mail *:",
      home_contactmodal_phone: "Ihre Telefonnummer *:",
      home_contactmodal_message: "Ihre Nachricht *:",
      home_contactmodal_close: "Schließen",
      home_contactmodal_send: "Senden",
      home_contactmodal_success: "Wir haben Ihre Nachricht erhalten und werden so schnell wie möglich antworten!",
      home_contactmodal_failure: "Etwas ist beim Senden der E-Mail schief gelaufen. Bitte versuchen Sie es erneut?",

      configurator_title: "Konfigurator",
      configurator_subtitle: "Beschreiben Sie Ihre persönliche Situation.",
      configurator_sorry: "Leider…",
      configurator_notowner:
        "Sie geben an, dass Ihr derzeitiger Wohnsitz nicht Ihnen gehört. Wir können nur mit Hausbesitzern zusammenarbeiten, um Solarmodule zu installieren. Wenn Sie an unseren Modulen interessiert sind, bitten Sie Ihren Vermieter, uns zu kontaktieren!",
      configurator_question1: "Welches Dachmaterial haben Sie derzeit?",
      configurator_question1option1: "Dachziegel",
      configurator_question1option2: "Naturschiefer",
      configurator_question1option3: "Flachdach",
      configurator_question1option4: "Andere",
      configurator_question1reminder: "Bitte wählen Sie ein Dachmaterial aus.",
      configurator_question2:
        "Wie viele Solarmodule möchten Sie und wie viele passen auf Ihr Dach? Wir empfehlen 16 Module für einen 5-kW-Wechselrichter.",
      configurator_question2subtitle: "1 Platte ist ungefähr 2 m² groß.",
      configurator_question2placeholder: "Wählen Sie eine Zahl.",
      configurator_question2reminder: "Bitte wählen Sie einige Panels aus.",
      configurator_question2option1: "Weniger als 6",
      configurator_question2option2: "Mehr als 30",
      configurator_question3: "Bevorzugen Sie eine traditionelle Installation?",
      configurator_question3option1: "Welche Installation bevorzugen Sie?",
      configurator_question3option2: "Kostenlose Variante",
      configurator_question3option3: "Schnelles Gewinngeschäft",
      configurator_question3more: "Unklar? Lesen Sie hier mehr",
      configurator_question4: "Interessieren Sie sich für einen Akku oder eine Ladestation?",
      configurator_question5: "Möchten Sie die Paneele selbst installieren (DIY)?",
      configurator_question6: "",
      configurator_summary_owner: "Dieses Gebäude gehört mir *",
      configurator_summary_email: "E-Mail *",
      configurator_summary_phone: "Telefon *",
      configurator_summary_name: "Name *",
      configurator_summary_street: "Straße *",
      configurator_summary_number: "Nr *",
      configurator_summary_extra: "Postfach, …",
      configurator_summary_city: "Stadt *",
      configurator_summary_zip: "Postleitzahl *",
      configurator_summary_reminder: "Bitte füllen Sie alle Pflichtfelder aus.",
      configurator_summary_captcha_reminder:
        "Bitte aktivieren Sie das Kästchen 'Ich bin kein Roboter' oben. Nichts zu sehen? Bitte laden Sie die Seite neu.",
      configurator_send: "Senden",
      configurator_sent: "Gesendet",
      configurator_sent_ok1:
        "Vielen Dank für Ihre Anfrage! Sie erhalten in Kürze einen Preisvorschlag an die von Ihnen angegebene E-Mail-Adresse:",
      configurator_sent_ok2:
        ". Sie haben keine E-Mail erhalten? Bitte überprüfen Sie Ihren Spam, versuchen Sie es erneut oder kontaktieren Sie uns unter +32456651937. Bis bald!",
      configurator_summary_send_nok:
        "Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.",

      calculator_subtitle: "Berechnen Sie die Kosten und Amortisation jeder unserer Lösungen:",
      calculator_chosen_solution: "Gewählte Lösung:",
      calculator_chosen_consumption: "Ihr Jahresverbrauch:",
      calculator_amount_panels: "Anzahl der Panels:",
      calculator_battery: "inkl. Batterie:",
      calculator_title: "Preisrechner",
      calculator_calculate: "Jetzt berechnen",
      calculator_consumption: "Ihr aktueller Verbrauch:",
      calculator_current_cost: "Geschätzte durchschnittliche Kosten pro kW:",
      calculator_current_yearcost: "Ihre aktuellen Kosten / Jahr:",
      calculator_power: "Leistung des Solarmoduls:",
      calculator_our_price: "Unser Preis, inkl. 6% Steuern:",
      calculator_subsidy: "Prämie:",
      calculator_final_cost: "Gesamtkosten:",
      calculator_profit_per_year: "Gewinn pro Jahr*:",
      calculator_profit_explanation0:
        "*Gewinn = Anzahl kWh Energie, die nicht mehr aus dem Netz bezogen wird, abzüglich Kosten für Solar Switch.",
      calculator_profit_explanation50:
        "*Gewinn = Anzahl der kWh Energie, die nicht mehr aus dem Netz bezogen wird = kWp der Anlage x Energiekosten von 0,50 Euro/kWh x verbrauchter % der erzeugten Energie.",
      calculator_profit_explanation100:
        "*Gewinn = Anzahl kWh Energie, die nicht mehr aus dem Netz bezogen wird + erhaltene Einspeisevergütung.",
      calculator_cost_per_year: "Solarschalterkosten pro Jahr:",
      calculator_payback_time: "Amortisationszeit:",
      calculator_no_cost: " (0 Euro Gesamtkosten.)",
      calculator_price_title: "Über unsere Preise",
      calculator_price_indicative:
        "Überrascht von unseren niedrigen Preisen? Zu Recht! Beachten Sie jedoch, dass alle auf dieser Seite angezeigten Preise nur eine Indikation sind. Dieser Preis kann zum Beispiel dann korrekt sein, wenn Sie unter anderem: ein Ziegeldach haben, in der Nähe von Kortrijk wohnen und 1 großes Dachsegment mit Paneelen ausstatten möchten.",
      calculator_price_indicative2:
        "Möchten Sie mehrere Dachbereiche mit Solarpanelen abdecken? Haben Sie ein Dachmaterial, das schwerer zu verarbeiten ist (z.B. Naturschiefer)? Wohnen Sie weit von unserem Lager entfernt, was höhere Transportkosten bedeutet? Haben Sie den ganzen Tag Schatten auf Ihrem Dach? Dies sind einige Faktoren, die den Preis höher treiben können. Jede Situation ist anders. Fordern Sie unverbindlich ein maßgeschneidertes Angebot an!",
      calculator_year: "Jahr.",
      calculator_diy: "Ich installiere es selbst:",
      calculator_power_efficiency1: "Bei dieser Berechnung werden 30 % der erzeugten Energie sofort verbraucht.",
      calculator_power_efficiency2: "Bei dieser Berechnung werden 50 % der erzeugten Energie sofort verbraucht.",

      solutions_title: "Unsere Lösungen",
      solutions_50title: "Schnelles Gewinngeschäft",
      solutions_subtitle: "Unsere 3 Lösungen im Detail.",
      solutions_small_letters: "So funktioniert es: ",
      solutions_50subtitle: "Amortisation Ihrer Investitionskosten in beispiellos kurzer Zeit",
      solutions_50paragraph1:
        "Mit der Quick-Profit-Lösung erhalten Sie Panels zu einem sehr wettbewerbsfähigen Preis, abhängig von Ihrer spezifischen Situation, bis zu mehr als der Hälfte des Preises bei Mitbewerbern. Dies ist möglich, da ein Vertrag unterzeichnet wird, in dem wir uns damit einverstanden erklären, dass Solar Switch Ihr Dach für die Installation von Solarmodulen für einen Zeitraum von 30 Jahren nutzt. Nach der Installation können Sie die von den Panels erzeugte Energie frei nutzen. Wenn die Module ins Netz eingespeist werden, wird die Vergütung an Solar Switch gezahlt. Aufgrund des niedrigen Preises ist es möglich, mit dem gleichen Budget mehr Panels zu installieren. Dadurch wird Ihr Spitzenverbrauch von den Panels absorbiert und Ihre Kapazitätsrate sinkt. Ein zusätzlicher Vorteil einer größeren Anlage besteht darin, dass Sie mehr Fördermittel erhalten. Auf diese Weise beträgt die Amortisationszeit 2,5–4 Jahre, verglichen mit 6–7 Jahren bei Wettbewerbern. Solar Switch erhält 30 Jahre lang die Einspeisegebühr, wir sind also in die Geschichte involviert. Sollten Probleme auftreten, liegt es in unserem Interesse, diese so schnell wie möglich zu lösen. Wir kümmern uns auch um die Versicherung der Installation. Nach 30 Jahren gehen die Module in Ihr volles Eigentum über und jeglicher Vertrag mit Solar Switch endet. Von da an ist die Injektion auch für Sie da! Sie haben jederzeit die Möglichkeit, den Vertrag aufzukaufen und die Installation zu übernehmen.",
      solutions_50paragraph2:
        "Bei Bedarf können Sie die installierten Panels zu 100 % nutzen. Monatlich darf Ihr Verbrauch die Hälfte der insgesamt erzeugten Energie nicht überschreiten. Die andere Hälfte der Energie nutzen wir, um sie ins Netz einzuspeisen. Wenn Sie monatlich mehr als die Hälfte der erzeugten Energie verbrauchen, erhalten Sie von uns eine Rechnung. Der Richtpreis für diesen Verbrauch liegt bei etwa 20 Cent pro kWh und damit weit unter dem Strompreis, den man zahlt, wenn die Sonne nicht scheint. Sie fragen sich, in welchen Fällen Sie eine Rechnung erhalten könnten? Dies könnte daran liegen, dass es eine Winterperiode mit einem Monat gibt, in dem die Sonne sehr wenig schien. Oder vielleicht haben Sie eine kleinere Dachfläche, sodass Sie nicht viel Strom mit Solarmodulen versorgen können. Die häufigste Ursache ist jedoch ein erhöhter Konsum, denn dafür gibt es viele Gründe. Denken Sie zum Beispiel an die Anschaffung eines Elektroautos, eines Elektrokochfelds, einer Wärmepumpe, eines Whirlpools oder eines Schwimmbades. Oder wächst Ihre Familie (Partner, Kinder, ältere Bewohner, ...)? Wir empfehlen Ihnen daher dringend, die Zukunft zu berücksichtigen und überdimensioniert zu sein, wenn Sie über die Installation von Solarmodulen nachdenken. Seit der Abschaffung der Drehtheke gilt: Je mehr Solarpaneele auf Ihrem Dach, desto besser.",
      solutions_50paragraph3: "Eine kurze Zusammenfassung:",
      solutions_50_toomuch1: "Extrem niedriger Preis pro kWh --> superschnell amortisiert.",
      solutions_50_toomuch2:
        "Niedriger Preis = Möglichkeit, in eine größere Anlage zu investieren = geringere Kapazitätsrate und mehr Subventionen.",
      solutions_50_toomuch3:
        "Seit 30 Jahren ist die Einspeisung für Solar Switch --> Seit 30 Jahren profitieren wir selbst davon, die Anlage in Topform zu halten.",
      solutions_50_toomuch4:
        "Der Verbrauch kann bei einer Momentaufnahme 100 % der Panels betragen, auf monatlicher Basis möchten wir 50 % der Energie einspeisen. Mehrverbrauch wird in Rechnung gestellt.",
      solutions_50_toomuch5: "Nach 30 Jahren gehört die Anlage vollständig Ihnen.",
      solutions_50paragraph4:
        "Alle Energie, die verbraucht wird, wenn die Sonne nicht scheint, wird aus dem Netz entnommen. Diese Energie zahlen Sie an Ihren regulären Energieversorger.",
      solutions_50questions: "Hier finden Sie alle häufig gestellten Fragen zu unserem schnelles Gewinngeschäft.",
      solutions_0title: "Kostenlose Installation.",
      solutions_0subtitle: "Kein Risiko für Ihre Ersparnisse.",
      solutions_0paragraph1:
        "Bei dieser Lösung wird Ihr Dach für einen Zeitraum von 30 Jahren genutzt, um Solarpaneele zu installieren, die im Eigentum von Solar Switch bleiben. Allerdings wird dies kostenlos angeboten. Da Sie keine Kosten haben, beträgt die Amortisationszeit 0 Jahre. Im Gegenzug wird Ihnen ein niedriger fixer Stromvertrag für die von den Panels erzeugte Energie angeboten. Wir beabsichtigen, €0,19 pro verbrauchter kWh zu berechnen, Ihr spezifischer Preis wird in Ihrem Vertrag deutlich angegeben. Dieser Betrag folgt dem normalen Index im Laufe der Jahre. Dieser Preis ist viel niedriger als das, was Sie normalerweise zahlen, und Preisschocks auf dem Energiemarkt wie im Jahr 2022 werden Sie nicht mehr betreffen. Der Energieverbrauch, wenn die Sonne nicht scheint, wird von uns nicht berechnet, Sie erhalten Rechnungen dafür von Ihrem Standard-Energieversorger. Die Kosten für Ihren jährlichen Energieverbrauch werden deutlich sinken.",
      solutions_0questions: "Alle häufig gestellten Fragen zu unserer kostenlosen Installation finden Sie hier.",
      solutions_100title: "Traditionelle Installation.",
      solutions_100subtitle: "Eine sichere Investition, die sich langfristig auszahlt.",
      solutions_100paragraph1:
        "Jeder kennt die traditionelle Form der Installation von Solarmodulen. Die Installationskosten sind höher und müssen sofort und vollständig bezahlt werden. Danach genießen Sie während der Sonnenstunden kostenlose Energie und erhalten eine geringe Vergütung für Ihre Injektion. Da Ihre Injektionsgebühr recht niedrig ist, ist die Amortisationszeit viel länger als bei unseren anderen Lösungen.",
      solutions_100paragraph2:
        "Die hohe Investitionssumme, die in Ihre Solaranlage investiert wird, kann nicht für andere Investitionen genutzt werden. Dies ist aufgrund von Inflation unerwünscht. Eine neue Küche, ein neues Auto oder ein Baby unterwegs? Es ist interessant, diese Ausgaben sofort tätigen zu können. Je nach Ihren finanziellen Ressourcen und zukünftigen Investitionsplänen kann diese Art des Kaufs von Solaranlagen mehr oder weniger interessant sein.",
      solutions_100questions:
        "Sehen Sie sich hier alle häufig gestellten Fragen zu einer herkömmlichen Installation von Solarmodulen an.",
      product_title1: "Unsere verwendeten Materialien",
      product_paragraph1:
        "Auf dieser Seite gehen wir auf die wichtigsten Komponenten einer Solarpanel-Installation und die Handwerkskunst ein, die Sie von uns erwarten können. Solar Switch ist bestrebt, ein äußerst gutes Preis-Leistungs-Verhältnis anzubieten, und solides Material ist der allererste Schritt dazu. Niemand ist zufrieden mit einem Wechselrichter, der häufig Probleme bereitet, oder mit Modulen, die einem Frühlingssturm nicht standhalten.",
      product_paragraph2:
        "Solar Switch kauft keine direkten Produkte von außerhalb der Europäischen Union. Wussten Sie, dass die Anforderungen für Solarmodulinstallationen in Belgien zu den strengsten der Welt gehören? Und doch sind unsere Paneele immer noch viel feuersicherer als diese strengen Vorschriften, mehr darüber können Sie weiter unten auf dieser Seite lesen. Jeder Teil Ihrer Solar Switch-Installation trägt ein europäisches Qualitätszeichen und ist für die Verwendung in Belgien zugelassen.",
      product_1_title: "Solarplatten",
      product_1_description1:
        "Derzeit liefern wir ausschließlich Solarmodule der Marke DAS Solar. Dieses Panel ist komplett schwarz und erzeugt maximal 420W. Nach sorgfältiger Recherche kamen wir zu dem Schluss, dass diese Solarmodule das beste Preis-Leistungs-Verhältnis bieten. Dafür gibt es eine Reihe von Gründen.",
      product_1_description2:
        "-Das Solarmodul besteht zunächst vollständig aus Glas, während herkömmliche Solarmodule eine Kunststoffrückseite haben. Durch die Verwendung von Glas ist die Platte sehr feuerbeständig. Die meisten anderen Paneele haben die Brandklasse C, bei unseren Paneelen ist dies die sicherste Kategorie, die Brandklasse A.",
      product_1_description3:
        "-Die Energieeffizienz ist ausgezeichnet. Auch hier spielt das Glas eine Rolle: Aufgrund seiner Transparenz können auch die Sonnenstrahlen, die auf die Dachfläche treffen und dort reflektiert werden, in Energie umgewandelt werden. Dies bietet Vorteile beispielsweise auf Flachdächern und Dächern mit einer helleren Dacheindeckungsfarbe, aber auch an jedem Tag mit weniger strahlender Sonne.",
      product_1_description4:
        "-Het paneel is lichter dan de meeste andere en dus minder belastend voor de dakconstructie. Bovendien is het ook veiliger en een stuk aangenamer om te installeren.",
      product_1_description5: "-Das Panel ist äußerst zuverlässig und verfügt über eine 25-jährige Produktgarantie.",
      product_1_description6:
        "- Auch die Verschlechterung im Laufe der Zeit ist sehr begrenzt, nach 30 Jahren bieten die Panels immer noch 88 % ihrer ursprünglichen Kapazität. Das scheint vielleicht nicht viel zu sein, aber wenn Sie wissen, dass die meisten Solarmodule nach 30 Jahren weniger als 75 % behalten, werden Sie verstehen, dass dies außergewöhnlich ist. Wenn die Anlage also nach 30 Jahren vollständig in Ihr Eigentum übergeht, haben Sie immer noch eine hervorragende Rendite!",
      product_2_title: "Montagesystem",
      product_2_description1:
        "Van der Valk ist seit vielen Jahren ein führender Anbieter im Bereich Montagesysteme für Solarmodule. Bei einem so entscheidenden Bauteil gehen wir kein Risiko ein und setzen auf absolute Spitzenqualität. Es fallen zusätzliche Kosten an, die jedoch lebensbedrohliche Situationen wie weggeblasene Solarpaneele nicht überwiegen. Darüber hinaus amortisiert sich dank der Benutzerfreundlichkeit ein Großteil der Mehrkosten bei der Installation, da Van der Valk-Systeme einfach und schnell zu montieren sind. Deshalb haben wir keinen Zweifel daran, dass wir mit dieser Qualität gut zurechtkommen und dass unsere Kunden es uns danken werden. Darüber hinaus sind alle sichtbaren Konstruktionselemente komplett schwarz. Sie passen perfekt zu den komplett schwarzen Panels. Die unsichtbaren Teile können eine graue Farbe haben, wie auf dem Foto gezeigt.",
      product_3_title: "Wechselrichter",
      product_3_description1:
        "Im Bereich der Wechselrichter stehen die Chancen gut, dass Sie bei uns einen Solis mit 5 kW finden, egal ob in Hybridversion oder nicht. Abhängig von der spezifischen Situation kann ein anderer Typ verwendet werden. Leider gibt es derzeit keinen Wechselrichter, der so lange hält wie die anderen Komponenten. Die harte Realität ist, dass der Wechselrichter während der Lebensdauer Ihrer Solarmodulanlage wahrscheinlich ausgetauscht werden muss. Das ist bei allen Marken so. Glücklicherweise gibt Solis bereits eine 10-jährige Produktgarantie und diese Wechselrichter sind preislich konkurrenzfähig. Davon profitieren Sie! Der niedrige Preis ist ein unmittelbarer Vorteil und wenn sich herausstellt, dass der Wechselrichter außerhalb der Garantiezeit ausfällt, profitieren Sie erneut. Wenn Sie eine große Anlage installiert haben, bemerken Sie möglicherweise zwei Wechselrichter mit 5 kW anstelle eines größeren Wechselrichters. Das ist auch ein Vorteil: Sollte ein Wechselrichter ausfallen, hat man einen anderen Wechselrichter, mit dem man vorübergehend mit halber Leistung weitermachen kann. Darüber hinaus sind die Kosten für den Austausch geringer, als wenn man sofort wieder einen großen Wechselrichter kaufen müsste.",
      product_4_title: "Batterie",
      product_4_description1: "Bei der Installation von Batterien arbeiten wir gerne mit der Marke Pylontech zusammen. Auch hier ist das hervorragende Preis-Leistungs-Verhältnis ausschlaggebend. Ein weiterer Vorteil besteht darin, dass Batterien dieser Marke mit einer Vielzahl von Wechselrichtern kompatibel sind. Selbstverständlich bieten wir auch Akkus anderer Marken an. Der Einbau der Batterie kann direkt während der Installation von Solarmodulen erfolgen, in diesem Fall wird ein Hybrid-Wechselrichter installiert. Es ist auch möglich, zusätzlich eine Batterie in eine bestehende Solarpanel-Anlage einzubauen. Dabei handelt es sich um ein sogenanntes Retrofit. In diesem Fall wird neben Ihrem bestehenden Wechselrichter ein separater Wechselrichter installiert. Abhängig von Ihrer Situation wird eine Hochvolt- oder Niedervoltbatterie gewählt. Kontaktieren Sie uns für ein unverbindliches Angebot, das auf Ihre spezifische Situation zugeschnitten ist.",
      result_title: "Unsere Arbeit",
      result_paragraph:
        "Auf dieser Seite finden Sie einige Bilder kürzlich abgeschlossener Projekte, damit Sie eine Vorstellung davon haben, wie unser Endprodukt aussieht. Wenn Sie detailliertere Informationen über den aktuellen Stand der Ereignisse wünschen, schauen Sie sich gerne unsere sozialen Seiten an!",
      result_facebook: "Unsere Facebook-Seite",
      result_instagram: "Unsere Instagram-Seite",
      service_title: "Unser Wartungsservice",
      service_paragraph:
        "Neben der Installation von PV-Anlagen bieten wir auch 2 Serviceformeln an, um unsere Kunden weiter zu entlasten. Mehr dazu können Sie auf dieser Seite lesen. Alle unsere gebrauchten Materialien haben eine lange Garantiezeit und im Falle eines Defekts während der Garantie wird ein kostenloses Ersatzprodukt zur Verfügung gestellt. Alle weiteren Zusatzkosten werden jedoch in Rechnung gestellt. Denken Sie an die Anzahl der benötigten Arbeitsstunden, Transportkosten und Verwaltungskosten. Wenn Sie sicher sein möchten, dass Ihnen keine zusätzlichen Kosten mehr entstehen, Sie können sich für unsere Zusatzleistungen entscheiden. Selbstverständlich steht es Ihnen frei, keinen Servicevertrag zu wählen.",
      service_1_title1: "Serviceformel: Volle Garantie",
      service_1_description1:
        "Unser komplettes Garantieservice-Angebot kostet 75 Euro pro Jahr. Mit dieser Formel sind alle möglichen Zusatzkosten während der Garantiezeit der Produkte abgedeckt. Keine Rechnungen für Transportkosten, Arbeitskosten, Verwaltungskosten oder andere Kosten. Obendrein, Wir installieren ein intelligentes Gerät, das Ihre erzeugte Energie automatisch überwacht und uns bei Problemen direkt benachrichtigt, damit wir sofort zur Rettung kommen und Ihre Anlage jederzeit in Topform halten können!",
      service_2_title1: "Serviceformel: All-In",
      service_2_description1:
        "Unser All-in-Service kostet jährlich 125 Euro. Sie erhalten 30 Jahre lang vollen Support. Sollte eine Komponente Probleme haben, wird diese sofort ausgetauscht, ohne weitere Rechnungen. Es gibt 2 Ausnahmen: Batterien sind nicht abgedeckt nach dieser Formel und wir werden nicht eingreifen, wenn die Installation manipuliert wurde.",
      faq_title: "Häufig gestellte Fragen",
      faq_question_1: "Sind Sonnenkollektoren mit dem Kapazitätstarif noch sinnvoll?",
      faq_answer_1:
        "Solarmodule können dazu beitragen, die kosten für die Kapazitätstarif niedrig zu halten. Wenn Sie tagsüber einen hohen Verbrauch haben (z. B. gleichzeitig: Kochen, Waschmaschine oder Trockner benutzen, Elektroauto aufladen), dann ist die gesamte Energie, die Ihre Paneele liefern, bereits eine Reduzierung Ihres Spitzenverbrauchs zu diesem Zeitpunkt. Wer das nicht ständig selbst überwachen möchte, dem empfehlen wir den Einbau eines smarten Controllers, der beispielsweise das Laden des Autos unterbricht, wenn eine Wolke vor der Sonne hängt. Eine weitere Option ist der Einbau einer Batterie.",
      faq_question_2: "Was ist, wenn ich umziehe?",
      faq_answer_2:
        "Da die Installation ortsgebunden ist und nicht verschoben werden kann, muss der neue Eigentümer den Vertrag übernehmen. Wenn dies nicht möglich ist, haben Sie die Möglichkeit, die Solaranlage zu kaufen oder von uns entfernen zu lassen. Die spezifischen Kosten hierfür hängen von der gewählten Installationsart ab und werden im Vertrag angegeben.",
      faq_question_3: "Installieren Sie Paneele auf einem Asbestdach?",
      faq_answer_3: "Im Moment nicht, aus Sicherheitsgründen für unsere Mitarbeiter. Möglicherweise in der Zukunft.",
      faq_question_4: "Wie viel kW produziert eine Anlage pro Stunde oder pro Tag?",
      faq_answer_4:
        "Die jeweilige Leistung hängt ganz von Ihrer persönlichen Situation ab. Der Ertrag hängt von der Windrichtung Ihrer Module, der Dachneigung, dem Sonnenstand zur jeweiligen Jahreszeit, dem Grad der Bewölkung und natürlich der Anzahl der installierten Module ab. Kontaktieren Sie uns für ein maßgeschneidertes Angebot und Sie erhalten eine beigefügte Grafik mit den Jahres- und Monatsdurchschnitten für Ihre Installation an Ihrer Adresse.",
      faq_question_5: "Ich habe bereits Panels und möchte nur eine Ladestation und/oder einen Akku.",
      faq_answer_5:
        "Das ist möglich, in diesem Fall empfehlen wir das Ausfüllen des Kontaktformulars auf der Homepage. Dann kann ein persönliches Angebot erstellt werden.",
      faq_question_6: "Wie viele Panels sollte ich idealerweise installieren?",
      faq_answer_6:
        "Wir empfehlen eine Überdimensionierung und wenn möglich die Installation von mindestens 16 Panels (6700 Watt Peak). Dadurch können Sie das Beste aus einem 5-kW-Wechselrichter herausholen und sind für eine Zukunft mit einem möglichen Elektroauto sicher.",
      faq_question_7: "Welche Kopfgelder kann ich noch bekommen?",
      faq_answer_7:
        "Im Jahr 2023 können Sie maximal 750 Euro Förderung erhalten, im Jahr 2024 keine Förderungen.",
      faq_question_8: "Wie oft reinige ich meine Panels?",
      faq_answer_8:
        "Um Ihren Ertrag optimal zu halten, befinden sich so wenig Staub und Schmutz wie möglich auf den Platten. In der Praxis empfehlen wir Ihnen, Ihre Platten mindestens einmal im Jahr gründlich zu waschen. Öfter ist besser.",
      faq_question_9: "Bekomme ich einen Rabatt, wenn ich meine eigene Installation installiere?",
      faq_answer_9:
        "Ja! Zum Zeitpunkt des Verfassens dieses Artikels gewähren wir Heimwerkern einen Rabatt von 600 €, da Sie uns einen Großteil der Arbeitszeit ersparen. Bitte beachten Sie: In diesem Fall übernehmen wir keine Verantwortung bzgl Brandschutz und Garantie",
      faq_question_10: "Wo finde ich eine Erklärung zur Installation von Solarmodulen selbst?",
      faq_answer_10:
        "Wir arbeiten derzeit an einem umfangreichen Tutorial auf unserer Website, um Heimwerkern zu helfen, also kommt es bald.",
      faq_subtitle50: "Über den schnelles Gewinngeschäft",
      faq_question_50_1: "Warum bleiben die Solarpaneele Eigentum von Solar Switch, anstatt sie zu verkaufen?",
      faq_answer_50_1:
        "Dies geschieht, um uns vor finanziellen Problemen zu schützen. Angenommen, die Paneele gehen in das Eigentum der Person über, auf deren Dach sie platziert sind. Wenn diese Person Schulden macht, können die Gläubiger alle Paneele beschlagnahmen. Aber in unserem Geschäftsmodell wir rechnen mit 30 Jahren Injektionszeit.",
      faq_question_50_2: "Installieren Sie auch Batterien zu Ihren niedrigen Preisen?",
      faq_answer_50_2:
        "Wenn Ihre Installation groß genug ist, bauen wir auf Wunsch auch eine Batterie zu unserem günstigen Preis ein.",
      faq_question_50_3: "Mit wie vielen Rechnungen kann ich rechnen?",
      faq_answer_50_3:
        "Wenn Sie monatlich mehr als die Hälfte der erzeugten Solarenergie verbrauchen, stellen wir Ihnen die verbrauchte überschüssige Energie in Rechnung. Das kann in einem schlechten Wintermonat durchaus der Fall sein. Zusätzlich erhalten Sie eine zweite Rechnung Ihres regulären Energieversorgers für die gesamte Energie, die Sie aus dem Netz bezogen haben.",
      faq_question_50_4: "",
      faq_answer_50_4: "",
      faq_question_50_5: "",
      faq_answer_50_5: "",
      faq_subtitle0: "Über das kostenlose Angebot",
      faq_question_0_1: "Bauen Sie auch kostenlose Batterien ein?",
      faq_answer_0_1:
        "Nein. Batterien sind sehr teuer in der Anschaffung und wir könnten finanziell nicht überleben, wenn wir sie in der kostenlosen Lösung anbieten würden.",
      faq_question_0_2: "Endet der feste Stromvertrag jemals?",
      faq_answer_0_2:
        "Ja. Nach 30 Jahren läuft der Vertrag aus und die Panels werden Ihr Eigentum. Ab diesem Zeitpunkt können Sie die von Ihren Panels erzeugte Energie kostenlos nutzen. Zu diesem Zeitpunkt haben Sie keine Vertragsform mehr mit Solar Switch.",

      footer_companyname: "Solar Switch BV",
      footer_address1: "Hof Ter Melle 44",
      footer_address2: "8501 Heule",
      footer_clients: "Einzelpersonen",
      footer_solarpanels: "Solarmodullösungen",
      footer_faq: "Häufig gestellte Fragen",
      footer_calculator: "Preisrechner",
      footer_professionals: "Unternehmen & Fachleute",
      footer_contact: "Kontaktieren Sie uns",
      footer_conditions: "Allgemeine Geschäftsbedingungen",
      footer_privacy: "Datenschutzerklärung",
      footer_rightsreserved: "Alle Rechte vorbehalten",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    lng: "nl",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
