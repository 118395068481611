import React, {useState} from "react";
import { useTranslation } from 'react-i18next';

export default function ConfiguratorQuestion4({onPreviousClick, onNextClick, onBatterySelected}) {
  const { t } = useTranslation();
  const [batterySelected, setBatterySelected] = useState(0);
  
  const updateBatterySelected = (wantBattery) => {
    setBatterySelected(wantBattery);
    onBatterySelected(wantBattery);
  }

  return(
    <div class="border bg-light container roundedCard darkText">
      <div class="row">
        <div class="col smallMarginTop">
          <i className="marginBottom fa-solid fa-battery-half fa-2xl"></i>
          <p class="noMargin">{t('configurator_question4')}</p>
        </div>
      </div>

      <div class="marginTop">
          <button class={"configuratorButton smallPadding smallMarginRight btn btn-" + (batterySelected === 0 ? "success" : "dark")} onClick={() => updateBatterySelected(0)}>{t('no')}</button>
          <button class={"configuratorButton smallPadding smallMarginLeft btn btn-" + (batterySelected === 1 ? "success" : "dark")} onClick={() => updateBatterySelected(1)}>{t('yes')}</button>
      </div>
      
      <div class="row smallMarginBottom marginTop">
        <div class="col"><button class="configuratorButton btn btn-outline-success" onClick={() => onPreviousClick()}>{t('back')}</button></div>
        <div class="col"><button class="configuratorButton btn btn-success" onClick={() => onNextClick()}>{t('ok')}</button></div>
      </div>
    </div>
  );
}