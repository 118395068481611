import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ConfiguratorQuestion6({ onPreviousClick, onNextClick, onConsumptionSelected }) {
  const { t } = useTranslation();
  const [questionAnswered, setQuestionAnswered] = useState(0);
  const [consumption, setConsumption] = useState(3500);

  const onAnswerConsumption = (userConsumption) => {
    setQuestionAnswered(1);
    setConsumption(userConsumption);
    onConsumptionSelected(userConsumption);
  };

  const onAnswerQuestion = () => {
    setQuestionAnswered(1);
    onNextClick(true);
  };

  return (
    <div class="border bg-light container roundedCard darkText">
      <div class="row justify-content-center marginBottom">
        <div class="col smallMarginTop">
          <i className="marginBottom fa-solid fa-bolt fa-2xl" />
          <p>{t("configurator_question6")}</p>
          <select
            class="form-select"
            aria-label="set consumption"
            defaultValue={"3500"}
            onChange={(e) => {
              onAnswerConsumption(parseInt(e.target.value));
            }}
          >
            <option value="1000">1000kWh</option>
            <option value="1500">1500kWh</option>
            <option value="2000">2000kWh</option>
            <option value="2500">2500kWh</option>
            <option value="3000">3000kWh</option>
            <option value="3500">3500kWh</option>
            <option value="4000">4000kWh</option>
            <option value="4500">4500kWh</option>
            <option value="5000">5000kWh</option>
            <option value="5500">5500kWh</option>
            <option value="6000">6000kWh</option>
            <option value="6500">6500kWh</option>
            <option value="7000">7000kWh</option>
            <option value="7500">7500kWh</option>
            <option value="8000">8000kWh</option>
          </select>
        </div>
      </div>
      <div class="row smallMarginBottom">
        <div class="col">
          <button class="configuratorButton btn btn-outline-success" onClick={() => onPreviousClick()}>
            {t("back")}
          </button>
        </div>
        <div class="col">
          <button class="configuratorButton btn btn-success" onClick={() => onAnswerQuestion()}>
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
}
