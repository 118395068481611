import React from "react";
import { useTranslation } from "react-i18next";

export default function Service() {
  const { t } = useTranslation();

  return (
    <div class="container-fluid treesBackgroundImage">
      <div class="container solutionsContainer">
        <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
          <h2>{t("service_title")}</h2>
          <p>{t("service_paragraph")}</p>
          <div class="col-12">
            <h5 class="smallMarginTop">{t("service_1_title1")}</h5>
            <p class="smallMarginTop">{t("service_1_description1")}</p>
          </div>
          <div class="row">
          <div class="col-12 text-center">
            <img class="serviceImage" alt="solar panel service" src={require("../images/result5.webp")} />
          </div>
          </div>
          <div class="col-12">
            <h5 class="smallMarginTop">{t("service_2_title1")}</h5>
            <p class="smallMarginTop">{t("service_2_description1")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
