import React from "react";
import { useTranslation } from 'react-i18next';

export default function FAQQuestion({id}) {
  const { t } = useTranslation();
   return(
    <div id={id} class="col-xxxl-2 col-xxl-3 col-xl-4 col-lg-6 col-sm-12 darkText smallPadding">
      <div id={id} class="whiteTransparentOverlay roundedCard smallPadding">
      <h4>{t("faq_question_" + id)}</h4>
      <p>{t("faq_answer_" + id)}</p>
      </div>
    </div>
  );
}