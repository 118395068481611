import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import FAQ from "./pages/FAQ";
import NewRequestConfigurator from "./pages/NewRequestConfigurator";
import Solutions from "./pages/Solutions";
import Legal from "./pages/Legal";
import Calculator from "./pages/Calculator";
import BatteryCalculator from "./pages/BatteryCalculator";
import Thanks from "./pages/Thanks";
import Products from "./pages/Products";
import Service from "./pages/Service";
import Result from "./pages/Result";
import Contact from "./pages/Contact";

const Router = () => {
  return (
    <Routes>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/mijn" element={<Home />}></Route>
      <Route exact path="/index.html" element={<Home />}></Route>
      <Route exact path="/home" element={<Home />}></Route>
      <Route exact path="/faq" element={<FAQ />}></Route>
      {/*<Route exact path="/solutions" element={<Solutions />}></Route>*/}
      <Route exact path="/configurator" element={<NewRequestConfigurator />}></Route>
      <Route exact path="/legal" element={<Legal />}></Route>
      <Route exact path="/calculator" element={<Calculator />}></Route>
      <Route exact path="/batterycalculator" element={<BatteryCalculator />}></Route>
      <Route exact path="/product" element={<Products />}></Route>
      <Route exact path="/service" element={<Service />}></Route>
      <Route exact path="/result" element={<Result />}></Route>
      <Route exact path="/simulationdone" element={<Thanks />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};

export default Router;
