import React from "react";
import { useTranslation } from 'react-i18next';
import ContactForm from "../components/ContactForm";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div class="container-fluid treesBackgroundImage">
      <div class="container solutionsContainer">
        <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}