import React from "react";
import Icon from "../images/Icon";
import { withTranslation, useTranslation } from "react-i18next";
import i18n from "../i18n";

function Navbar() {
  const { t } = useTranslation();

  const getLanguage = () => {
    const langCode = i18n.resolvedLanguage;
    if (langCode.includes("en")) {
      return t("nav_english");
    } else if (langCode.includes("fr")) {
      return t("nav_french");
    } else if (langCode.includes("de")) {
      return t("nav_german");
    }
    return t("nav_dutch");
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav class="navbar navbar-expand-lg navbar-dark dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <Icon />
        </a>
        <button
          class="navbar-toggler toggleButton"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="btn btn-success" href="/configurator">
                <span class="bold fontsizedefault whiteColor">{t("home_orderstartbutton")}</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">
                {t("nav_home")}
              </a>
            </li>
            {/*<li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="/solutions"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("nav_solutions")}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" href={window.location.origin + "/solutions#50"}>
                    {t("nav_50-50")}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href={window.location.origin + "/solutions#0"}>
                    {t("nav_no_costs")}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href={window.location.origin + "/solutions#100"}>
                    {t("nav_traditional")}
                  </a>
                </li>
              </ul>
  </li>*/}
            <li class="nav-item">
              <a class="nav-link" href={window.location.origin + "/product"}>
                {t("nav_products")}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={window.location.origin + "/result"}>
                {t("nav_result")}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={window.location.origin + "/service"}>
                {t("nav_service")}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/faq">
                {t("nav_faq")}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href={window.location.origin + "/contact"}>
                {t("nav_contactus")}
              </a>
            </li>
          </ul>

          <ul class="nav navbar-nav navbar-right">
            <li class="nav-item dropdown">
              <i className="whiteColor fa-solid fa-globe fa-2xl" />
              <a
                class="nav-link dropdown-toggle  displayInitial"
                id="languageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {" "}
                {getLanguage()}
              </a>
              <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      changeLanguageHandler("nl");
                    }}
                  >
                    {t("nav_dutch")}
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      changeLanguageHandler("fr");
                    }}
                  >
                    {t("nav_french")}
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      changeLanguageHandler("de");
                    }}
                  >
                    {t("nav_german")}
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      changeLanguageHandler("en");
                    }}
                  >
                    {t("nav_english")}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default withTranslation()(Navbar);
