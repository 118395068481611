import React from "react";
import { useTranslation } from "react-i18next";

export default function Products() {
  const { t } = useTranslation();

  return (
    <div>
      <div class="container-fluid treesBackgroundImage">
        <div class="container solutionsContainer">
          <div class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <div class="col-12">
              <h2>{t("product_title1")}</h2>
              <p>{t("product_paragraph1")}</p>
              <p>{t("product_paragraph2")}</p>
            </div>
          </div>

          <div id="panels" class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <h2>{t("product_1_title")}</h2>
            <div class="col-12 col-md-4 smallPadding smallMarginBottom text-center">
              <img
                id="solarpanelimage"
                class="fullWidthImage"
                alt="DAS Solar panel"
                src={require("../images/solarpanel.webp")}
              />
            </div>
            <div class="col-12 col-md-8">
              <p class="smallMarginTop">{t("product_1_description1")}</p>
              <p class="smallMarginTop">{t("product_1_description2")}</p>
              <p class="smallMarginTop">{t("product_1_description3")}</p>
              <p class="smallMarginTop">{t("product_1_description4")}</p>
              <p class="smallMarginTop">{t("product_1_description5")}</p>
              <p class="smallMarginTop">{t("product_1_description6")}</p>
            </div>
          </div>

          <div
            id="roofconstruction"
            class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom"
          >
            <h2>{t("product_2_title")}</h2>
            <div class="col-12 col-md-8">
              <p class="smallMarginTop">{t("product_2_description1")}</p>
            </div>
            <div class="col-12 col-md-4 smallPadding smallMarginBottom">
              <img class="fullWidthImage" alt="Van der Valk montage" src={require("../images/montage.webp")} />
            </div>
          </div>

          <div id="converter" class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <h2>{t("product_3_title")}</h2>
            <div class="col-12 col-md-4 smallPadding smallMarginBottom text-center">
              <img
                id="converterimage"
                class="fullWidthImage"
                alt="Solis converter"
                src={require("../images/converter.png")}
              />
            </div>
            <div class="col-12 col-md-8">
              <p class="smallMarginTop">{t("product_3_description1")}</p>
            </div>
          </div>

          <div id="battery" class="row whiteBackground roundedCard darkText containerPadding smallMarginBottom">
            <h2>{t("product_4_title")}</h2>
            <div class="col-12 col-md-8">
              <p class="smallMarginTop">{t("product_4_description1")}</p>
            </div>
            <div class="col-12 col-md-4 smallPadding smallMarginBottom">
              <img class="fullWidthImage" alt="Pylontech battery" src={require("../images/battery.jpeg")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
