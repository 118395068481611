import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { withRouter } from "../components/WithRouter";
import { withTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import ConfiguratorQuestion1 from "../components/ConfiguratorQuestion1";
import ConfiguratorQuestion2 from "../components/ConfiguratorQuestion2";
import ConfiguratorQuestion3 from "../components/ConfiguratorQuestion3";
import ConfiguratorQuestion4 from "../components/ConfiguratorQuestion4";
import ConfiguratorSummary from "../components/ConfiguratorSummary";
import ConfiguratorQuestion5 from "../components/ConfiguratorQuestion5";
import axios from "axios";
import ConfiguratorQuestion6 from "../components/ConfiguratorQuestion6";

class NewRequestConfigurator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      sendStatus: 0,
      batteryOrChargerWanted: 0,
      numberOfPanels: 0,
      diyWanted: 0,
      solution: 3,
      roofMaterial: 0,
      showSummary: false,
      consumption: 0,
      email: "",
      slideData: [
        {
          index: 0,
          content: (
            <ConfiguratorQuestion1
              onRoofMaterialSelected={(roofMaterial) => this.setState({ ...this.state, roofMaterial })}
              onPreviousClick={() => this.onPreviousClick()}
              onNextClick={() => this.onNextClick()}
            />
          ),
        },
        {
          index: 1,
          content: (
            <ConfiguratorQuestion2
              onNumberOfPanelsSelected={(numberOfPanels) => this.setState({ ...this.state, numberOfPanels })}
              onPreviousClick={() => this.onPreviousClick()}
              onNextClick={() => this.onNextClick()}
            />
          ),
        },
        {
          index: 2,
          content: (
            <ConfiguratorQuestion4
              onBatterySelected={(batteryOrChargerWanted) => this.setState({ ...this.state, batteryOrChargerWanted })}
              onPreviousClick={() => this.onPreviousClick()}
              onNextClick={() => this.onNextClick()}
            />
          ),
        },
        {
          index: 3,
          content: (
            <ConfiguratorQuestion6
              onConsumptionSelected={(consumption) => this.setState({ ...this.state, consumption })}
              onPreviousClick={() => this.onPreviousClick()}
              onNextClick={(showSummary) => this.onNextClick(showSummary)}
            />
          ),
        },
      ],
    };
  }

  onPreviousClick() {
    if (this.state.current === 0) {
      this.props.navigate("/");
    }
    const current = this.state.current - 1;
    this.setState({ ...this.state, showSummary: false, current });
  }

  onNextClick(showSummary = false) {
    let next = this.state.current + 1;
    /*if (next === 3 && this.state.solution === 2) {
      next++;
    }*/
    this.setState({ ...this.state, showSummary, current: next });
  }

  setConsumption(consumption) {
    this.setState({ consumption }, () => {
      this.onNextClick();
    });
  }

  onSend(personalInfo) {
    const dakType = this.state.roofMaterial === 1 ? "Dakpannen" : this.state.roofMaterial === 3 ? "Plat dak" : "Andere";
    const aantalPanelen = this.state.numberOfPanels;
    /*const oplossing =
      this.state.solution === 3
        ? "Traditionele installatie"
        : this.state.solution === 2
        ? "Gratis installatie"
        : "Snelle winst deal";*/
    const batterijOfOpladerGewenst = this.state.batteryOrChargerWanted === 1 ? "Ja" : "Nee";
    //const doeHetZelver = this.state.diyWanted === 1 ? "Ja." : "Nee";
    const contactInfo = personalInfo;
    const usage = this.state.consumption
    const content = { dakType, aantalPanelen, oplossing: "Traditionele installatie", batterijOfOpladerGewenst, usage, contactInfo };
    axios
      .post(process.env.REACT_APP_CONFIG_URL, { content })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ ...this.state, sendStatus: 1, showSummary: false, email: personalInfo.email });
        }
      })
      .catch((error) => {
        console.log("Error response: " + error);
        this.setState({ ...this.state, sendStatus: 2, showSummary: false, email: personalInfo.email });
      });
  }

  render() {
    const { current } = this.state;
    const wrapperTransform = {
      transform: `translateX(-${current * (100 / this.state.slideData.length)}%)`,
    };
    return (
      <div>
        <div class="container-fluid treesBackgroundImage noOverflow">
          <div class="row">
            <div class="col darkText text-center">
              <h1>{this.props.t("configurator_title")}</h1>
              <p>{this.props.t("configurator_subtitle")}</p>

              <div className="slider">
                <ul className="slider__wrapper" style={wrapperTransform}>
                  {this.state.slideData.map((slide) => {
                    let classNames = "slide";

                    if (current === slide.index) classNames += " slide--current";
                    return (
                      <li key={slide.index} ref={this.slide} className={classNames}>
                        {slide.content}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {this.state.showSummary && (
                <ConfiguratorSummary
                  onPreviousClick={() => this.onPreviousClick()}
                  onSend={(personalInfo) => this.onSend(personalInfo)}
                />
              )}
              {this.state.sendStatus !== 0 && (
                <div class="container">
                  <div class="whiteTransparentOverlay roundedCard smallPadding">
                    <div class="row">
                      <div class="col">
                        <h5>{this.props.t("configurator_sent")}</h5>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      {this.state.sendStatus === 2 && (
                        <div class="col-12 col-md-6">
                          <div class="alert alert-danger" role="alert">
                            {this.props.t("configurator_summary_send_nok")}
                          </div>
                        </div>
                      )}
                      {this.state.sendStatus === 1 && (
                        <Navigate to={"/simulationdone"} state={{ mail: this.state.email }} />
                      )}
                    </div>
                    <div class="row">
                      <div class="col">
                        <a class="btn btn-success" href="/">
                          {this.props.t("ok")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(withTranslation()(NewRequestConfigurator));
