import React, { useState } from "react";
import styles from "./TextInput.css";

export default function TextInput({ className, label, errorText, id, ...rest }) {
  const [validationMessage, setValidationMessage] = useState("");

  const onInvalid = (e) => {
    const target = e.target;
    setValidationMessage(target.validationMessage);
  };

  const onBlur = (e) => {
    const target = e.target;

    if (!!validationMessage) {
      setValidationMessage(target.validationMessage);
    }
  };
  
  return(
    <div className={styles.wrapper}>
      <div>
        {label && (
          <div className={styles.label}>
            <label htmlFor={id}>{label}</label>
          </div>
        )}
      </div>
      <div className="relative">
        <input
          id={id}
          className={styles.input}
          onInvalid={onInvalid}
          onBlur={onBlur}
          {...rest}
        />
      </div>

      {!!validationMessage && (
        <div className={styles.validationMessage}>
          {errorText || validationMessage}
        </div>
      )}
    </div>
  );
}